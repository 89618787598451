import React from "react";
import { Row, Col, Card } from "antd";
import FlangedImages from "../static/Home/Flanged-pipes-fittings/index.js";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Award1 from "../static/Home/Awards/award-1.jpg";
import Award2 from "../static/Home/Awards/award-2.jpg";
import Award3 from "../static/Home/Awards/award-3.jpg";
import Award4 from "../static/Home/Awards/award-4.jpg";
import Award5 from "../static/Home/Awards/award-5.jpg";
import Award6 from "../static/Home/Awards/award-6.jpg";
const { Meta } = Card;

const ProductsHeader = () => (
  <div>
    <Fade bottom>
      <h1
        className="Mulish"
        style={{
          margin: "auto",
          fontWeight: 800,
          fontSize: "35px",
        }}
      >
        Perpetual Income <span style={{ color: "#F79489" }}> Team Awards</span>
      </h1>
      <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "0rem",
          color: "black",
        }}
      >
        Look at some of the many company vacations and lifestyle I have been
        blessed to now live.
      </p>{" "}
    </Fade>
  </div>
);

const Flanged = () => (
  <div>
    <Header />
    <Row
      style={{
        maxWidth: "70em",
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Col sm={24} md={24} style={{ marginTop: "9em", padding: "0.5em" }}>
        <ProductsHeader />
      </Col>
      <Col sm={24} md={24} style={{ margin: "auto", marginTop: "2em" }}>
        <p
          className="Mulish"
          style={{
            fontSize: 18,
            marginTop: "0rem",
            color: "black",
          }}
        >
          CNVRGE - 18 Februarie 2023
        </p>{" "}
      </Col>
      <Col sm={24} md={8} style={{ marginTop: "1em", padding: "1.5em" }}>
        <Card
          bordered={false}
          style={{
            width: "100%",
          }}
          cover={<img alt="example" src={Award1} />}
        >
          <Meta
            title={
              <span>
                <p
                  style={{
                    marginTop: "1em",
                    marginBottom: "-1.5em",
                  }}
                >
                  Linnette & Frikkie Badenhorst
                </p>
                <br />

                <p
                  style={{
                    fontSize: "20px",
                    color: "rgb(247, 148, 137)",
                    marginBottom: "-0.5em",
                  }}
                >
                  Millennium Award
                </p>
                <br />
              </span>
            }
            description={
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "-1em",
                  color: "black",
                  fontWeight: 400,
                }}
              >
                Award for adding more than 1000 products in a year 2022
              </p>
            }
          />
        </Card>
      </Col>
      <Col sm={24} md={8} style={{ marginTop: "1em", padding: "1.5em" }}>
        <Card
          bordered={false}
          style={{
            width: "100%",
          }}
          cover={<img alt="example" src={Award4} />}
        >
          <Meta
            title={
              <span>
                <p
                  style={{
                    marginTop: "1em",
                    marginBottom: "-1.5em",
                  }}
                >
                  Erina Lodewycks
                </p>
                <br />
                <p
                  style={{
                    fontSize: "20px",
                    color: "rgb(247, 148, 137)",
                    marginBottom: "-0.5em",
                  }}
                >
                  "Gold Leaf" Award
                </p>
                <br />
              </span>
            }
            description={
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "-1em",
                  color: "black",
                  fontWeight: 400,
                }}
              >
                Achieving the Highest Business Performance Quotient in 2022
              </p>
            }
          />
        </Card>
      </Col>
      <Col sm={24} md={8} style={{ marginTop: "1em", padding: "1.5em" }}>
        <Card
          bordered={false}
          style={{
            width: "100%",
          }}
          cover={<img alt="example" src={Award2} />}
        >
          <Meta
            title={
              <span>
                <p
                  style={{
                    marginTop: "1em",
                    marginBottom: "-1.5em",
                  }}
                >
                  Nick & Chani Van Tonder
                </p>
                <br />

                <p
                  style={{
                    fontSize: "20px",
                    color: "rgb(247, 148, 137)",
                    marginBottom: "-0.5em",
                  }}
                >
                  Scholarship Award
                </p>
                <br />
              </span>
            }
            description={
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "-1em",
                  color: "black",
                  fontWeight: 400,
                }}
              >
                Earning the most XP in 2022
              </p>
            }
          />
        </Card>
      </Col>
      <Col sm={24} md={8} style={{ marginTop: "1em", padding: "1.5em" }}>
        <Card
          bordered={false}
          style={{
            width: "100%",
          }}
          cover={<img alt="example" src={Award3} />}
        >
          <Meta
            title={
              <span>
                <p
                  style={{
                    marginTop: "1em",
                    marginBottom: "-1.5em",
                  }}
                >
                  Gloria Mzamo
                </p>
                <br />

                <p
                  style={{
                    fontSize: "20px",
                    color: "rgb(247, 148, 137)",
                    marginBottom: "-0.5em",
                  }}
                >
                  Chechaqua Award
                </p>
                <br />
              </span>
            }
            description={
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "-1em",
                  color: "black",
                  fontWeight: 400,
                }}
              >
                Highest performing newcomer in 2022
              </p>
            }
          />
        </Card>
      </Col>

      <Col sm={24} md={8} style={{ marginTop: "1em", padding: "1.5em" }}>
        <Card
          bordered={false}
          style={{
            width: "100%",
          }}
          cover={<img alt="example" src={Award5} />}
        >
          <Meta
            title={
              <span>
                <p
                  style={{
                    marginTop: "1em",
                    marginBottom: "-1.5em",
                  }}
                >
                  Yolandie du Toit
                </p>
                <br />

                <p
                  style={{
                    fontSize: "20px",
                    color: "rgb(247, 148, 137)",
                    marginBottom: "-0.5em",
                  }}
                >
                  Rank 4
                </p>
                <br />
              </span>
            }
            description={
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "-1em",
                  color: "black",
                  fontWeight: 400,
                }}
              >
                The first person who achieved a Captain's pin in the Argonauts
                Club
              </p>
            }
          />
        </Card>
      </Col>
      <Col sm={24} md={8} style={{ marginTop: "1em", padding: "1.5em" }}>
        <Card
          bordered={false}
          style={{
            width: "100%",
          }}
          cover={<img alt="example" src={Award6} />}
        >
          <Meta
            title={
              <span>
                <p
                  style={{
                    marginTop: "1em",
                    marginBottom: "-1.5em",
                  }}
                >
                  Phillip & Lynn Gendell
                </p>
                <br />

                <p
                  style={{
                    fontSize: "20px",
                    color: "rgb(247, 148, 137)",
                    marginBottom: "-0.5em",
                  }}
                >
                  Rank 4
                </p>
                <br />
              </span>
            }
            // description={
            //   <p
            //     style={{
            //       fontSize: "14px",
            //       marginTop: "-1em",
            //       color: "black",
            //       fontWeight: 400,
            //     }}
            //   >
            //   The first person who achieved a Captain's pin in the Argonauts Club
            //   </p>
            // }
          />
        </Card>
      </Col>
    </Row>{" "}
    <Row
      style={{
        maxWidth: "70em",
        margin: "auto",
        textAlign: "center",
        marginTop: "3em",
      }}
    >
      <Link
        to="/"
        className="Mulish"
        style={{
          border: "none",
          fontWeight: 800,
          fontSize: "16px",
          margin: "auto",
          color: "rgba(246, 150, 139, 1)",
        }}
      >
        <ArrowLeftOutlined style={{ marginRight: "0.5em" }} /> Back to Home
      </Link>
    </Row>{" "}
    <Footer />
  </div>
);

export default Flanged;
