import React from "react";
import { Row, Col } from "antd";
import FlangedImages from "../static/Home/Flanged-pipes-fittings/index.js";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const ProductsHeader = () => (
  <div>
    <Fade bottom>
      <h1
        className="Mulish"
        style={{
          margin: "auto",
          fontWeight: 800,
          fontSize: "35px",
        }}
      >
        <span style={{ color: "#F79489" }}>Gallery</span>
      </h1>
      <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "0rem",
          color: "black",
        }}
      >
        Look at some of the many company vacations and lifestyle I have been blessed to now live.
      </p>{" "}
    </Fade>
  </div>
);
const ProductsContent = () => (
  <div>
    <FlangedImages />
  </div>
);

const Flanged = () => (
  <div>
    <Header />
    <Row
      style={{
        maxWidth: "70em",
        margin: "auto",
        textAlign: "center",

      }}
    >
      <Col sm={24} md={24} style={{ marginTop: "7em", }}>
        <ProductsHeader />
      </Col>
    </Row>{" "}
    <ProductsContent />
    <Row
      style={{
        maxWidth: "70em",
        margin: "auto",
        textAlign: "center",
        marginTop: "3em",
      }}
    >
      <Link
        to="/"
        className="Mulish"
        style={{
          border: "none",
          fontWeight: 800,
          fontSize: "16px",
          margin: "auto",
          color: "rgba(246, 150, 139, 1)",
        }}
      >
        <ArrowLeftOutlined style={{ marginRight: "0.5em" }} /> Back to Home
      </Link>
    </Row>{" "}
    <Footer />
  </div>
);

export default Flanged;
