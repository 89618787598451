import React, { useState } from "react";
import ReactDOM from "react-dom";
import { PageHeader, Button, Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { slide as Burger, SubMenu, Item } from "burger-menu";
import "burger-menu/lib/index.css";
import { DownOutlined } from "@ant-design/icons";
import MainLogo from "../static/Home/main-logo.svg";
import "antd/dist/antd.css";

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div id="nav-toggle" onClick={() => setIsOpen(!isOpen)}>
        <span></span>
      </div>
      <Burger
        left
        className="burger-menu"
        isOpen={isOpen}
        selectedKey={"entry"}
        onClose={() => setIsOpen(false)}
      >
        <Link to="/">
          <img
            style={{
              marginBottom: "2em",
            }}
            src={MainLogo}
          ></img>{" "}
        </Link>
        <a href="/">
          <Item
            itemKey={"a"}
            text={"Home"}
            onClick={() => setIsOpen(false)}
          ></Item>
        </a>
        <a href="/#why-us">
          {" "}
          <Item
            itemKey={"b"}
            text={"Why Us"}
            onClick={() => setIsOpen(false)}
          ></Item>
        </a>
        <a href="#about-us">
          {" "}
          <Item
            itemKey={"c"}
            text={"About Us"}
            onClick={() => setIsOpen(false)}
          ></Item>
        </a>

        <a
          href="https://perpetualincomeorg.wordpress.com/blog/"
          target="_blank"
        >
          {" "}
          <Item
            itemKey={"d"}
            text={"Blogs"}
            onClick={() => setIsOpen(false)}
          ></Item>
        </a>
        <Link to="./gallery">
          {" "}
          <Item
            itemKey={"d"}
            text={"Gallery"}
            onClick={() => setIsOpen(false)}
          ></Item>
        </Link>
        <a href="/#contact-us">
          {" "}
          <Item
            itemKey={"d"}
            text={"Contact Us"}
            onClick={() => setIsOpen(false)}
          ></Item>
        </a>
        {/* <a href="/" >
          {" "}
          <Item itemKey={"e"} text={"Sign In"} onClick={() => setIsOpen(false)}></Item>
        </a>
        <a href="/">
          {" "}
          <Item itemKey={"f"} text={"Sign Up"} onClick={() => setIsOpen(false)}></Item>
        </a> */}

        <div className="waveWrapper waveAnimation">
          <div className="waveWrapperInner bgTop">
            <div
              className="wave waveTop"
              style={{
                backgroundImage:
                  "url(http://front-end-noobs.com/jecko/img/wave-top.png)",
              }}
            ></div>
          </div>
          <div className="waveWrapperInner bgMiddle">
            <div
              className="wave waveMiddle"
              style={{
                backgroundImage:
                  "url(http://front-end-noobs.com/jecko/img/wave-mid.png)",
              }}
            ></div>
          </div>
          <div className="waveWrapperInner bgBottom">
            <div
              className="wave waveBottom"
              style={{
                backgroundImage:
                  "url(http://front-end-noobs.com/jecko/img/wave-bot.png)",
              }}
            ></div>
          </div>
        </div>
      </Burger>
    </>
  );
};
const MainHeader = () => (
  <div
    id="Header"
    className="site-page-header-ghost-wrapper"
    style={{
      backgroundColor: "#f6968bd8",
      padding: "0.5em",
      paddingBottom: "0.5em",
    }}
  >
    <PageHeader
      style={{
        backgroundColor: "transparent",
        maxWidth: "110em",
        width: "90vw",
        margin: "auto",

        padding: 0,
      }}
      ghost={false}
      //  onBack={() => window.history.back()}
      title={
        <div>
          <Link to="/">
            <img
              className="header-logo"
              // style={{ width: "8em" }}
              src={MainLogo}
            ></img>{" "}
          </Link>
          <div class="main">
            <div class="circle"></div>
          </div>
        </div>
      }
      //  subTitle="This is a subtitle"
      extra={[
        <BurgerMenu />,
        <a href="/" className="mobile-display">
          <Button
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "0.8em",
              padding: "1em",
              paddingTop: 0,
              border: "none",
              fontWeight: 500,
              boxShadow: "none",
              fontSize: "17px",
            }}
          >
            Home
          </Button>
        </a>,
        <a href="/#why-us" className="mobile-display">
          <span
            style={{
              backgroundColor: "transparent",
              padding: "1em",
              paddingTop: 0,
              color: "white",
              marginTop: "1.6em",
              border: "none",
              fontWeight: 500,
              boxShadow: "none",
              fontSize: "17px",
            }}
          >
            Why Us
          </span>
        </a>,
        <a href="/#about-us" className="mobile-display">
          <span
            style={{
              backgroundColor: "transparent",
              padding: "1em",
              paddingTop: 0,
              color: "white",
              marginTop: "1.6em",
              border: "none",
              fontWeight: 500,
              boxShadow: "none",
              fontSize: "17px",
            }}
          >
            About
          </span>
        </a>,
        //<Button
        //  style={{
        //    color: "white",
        //    marginTop: "1.6em",
        //    border: "none",
        //    boxShadow: "none",
        //    fontWeight: 500,
        //    fontSize: "17px",
        //  }}
        //>
        //  <Dropdown overlay={menu}>
        //    <a
        //      className="ant-dropdown-link"
        //      onClick={(e) => e.preventDefault()}
        //    >
        //      Products <DownOutlined />
        //    </a>
        //  </Dropdown>
        //</Button>,
        <Link to="./gallery" className="mobile-display">
          <span
            style={{
              backgroundColor: "transparent",
              padding: "1em",
              color: "white",
              marginTop: "1.6em",
              border: "none",
              boxShadow: "none",
              fontWeight: 500,
              fontSize: "17px",
            }}
          >
            Gallery
          </span>
        </Link>,
        <a
          href="https://perpetualincomeorg.wordpress.com/blog/"
          target="_blank"
          className="mobile-display"
        >
          <span
            style={{
              backgroundColor: "transparent",
              padding: "1em",
              paddingTop: 0,
              color: "white",
              marginTop: "1.6em",
              border: "none",
              fontWeight: 500,
              boxShadow: "none",
              fontSize: "17px",
            }}
          >
            Blogs
          </span>
        </a>,
        <a href="/#contact-us" className="mobile-display">
          <span
            style={{
              backgroundColor: "transparent",
              padding: "1em",
              color: "white",
              marginTop: "1.6em",
              border: "none",
              boxShadow: "none",
              fontWeight: 500,
              fontSize: "17px",
            }}
          >
            Contact Us
          </span>
        </a>,
        <a href="https://perpetualincome.org/login" className="mobile-display">
          <Button
            style={{
              backgroundColor: "transparent",
              border: "1px solid white",
              padding: "1em",
              paddingTop: 0,
              color: "white",
              borderRadius: 50,
              boxShadow: "none",
              fontWeight: 500,
              fontSize: "17px",
              paddingTop: "0.3em",
              height: "2.5em",
              width: "11.5em",
            }}
            type="primary"
          >
            Team Eagle Training
          </Button>
        </a>,
        // <a href="https://perpetualincome.org/login" className="mobile-display">
        //   <Button
        //     style={{
        //       backgroundColor: "white",
        //       border: "1px solid white",
        //       padding: "1em",
        //       paddingTop: 0,
        //       color: "#F79489",
        //       borderRadius: 50,

        //       boxShadow: "none",
        //       fontWeight: 500,
        //       fontSize: "17px",
        //       paddingTop: "0.3em",
        //       height: "2.5em",
        //       width: "6.5em"
        //     }}
        //     type="primary"
        //   >
        //     Sign Up
        //   </Button>
        // </a>
      ]}
    >
      {/*<Descriptions size="small" column={3}>
         <Descriptions.Item label="Created">Lili Qu</Descriptions.Item>
         <Descriptions.Item label="Association">
           <a>421421</a>
         </Descriptions.Item>
         <Descriptions.Item label="Creation Time">2017-01-10</Descriptions.Item>
         <Descriptions.Item label="Effective Time">2017-10-10</Descriptions.Item>
         <Descriptions.Item label="Remarks">
           Gonghu Road, Xihu District, Hangzhou, Zhejiang, China
         </Descriptions.Item>
       </Descriptions>*/}
    </PageHeader>
  </div>
);

const Header = () => (
  <header className="Homepage">
    <MainHeader />
  </header>
);

export default Header;
