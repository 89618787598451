import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Card, Collapse, Modal } from "antd";
import styled from "styled-components";
import HomeImage from "../static/Home/home-img.png";
import DuePoint from "../static/Home/duepoint.png";
import ProductImage1 from "../static/Home/product-image-1.png";
import VisualizeImage from "../static/Home/visualize-img.png";
import LinnetteImage from "../static/Home/linnette-img.png";
import Justin from "../static/Home/Justin.jpg";
import VideoBack from "../static/Home/video-back.png";
import Johan from "../static/Home/Johan.jpg";
import ProIcon from "../static/Home/pro-icon.svg";
import ProIcon1 from "../static/Home/pro-icon-1.svg";
import ProIcon2 from "../static/Home/pro-icon-2.svg";
import ProIcon3 from "../static/Home/pro-icon-3.svg";
import ArrowBottomIcon from "../static/Home/arrow-bottom-icon.svg";
import GalleryHome from "../static/Home/bookshelf.png";
import GalleryHomeBack from "../static/Home/bookcase.png";
import GalleryHomeBackMobile from "../static/Home/bookcase-mobile.png";
import { Modal as SuccessModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import WingLeft from "../static/Home/wing-left.png";
import WingRight from "../static/Home/wing-right.png";
import Eifel from "../static/Home/eifel-img.png";
import LogoModal from "../static/Home/main-logo.png";
import EifelMobile from "../static/Home/eifel-img-mobile.png";
import HoriLine from "../static/Home/hori-line.svg";
import { CaretRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { CgArrowLongRight } from "react-icons/cg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const onChange = key => {
  console.log(key);
};
const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const { Panel } = Collapse;
const StyledInput = styled(Input)`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 1.2em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  height: 60px !important;
  padding: 4px 0px ;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 1px solid #F59489 !important;
  border-bottom: 1px solid #F59489 !important;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500 !important;
  color: #F59489 !important;
  box-shadow:none !important;
  :placeholder {
    color: black !important;
  }

  :hover {
    border-bottom: 1px solid #F59489  !important;
  }
  :active{
    border-bottom: 1px solid #F59489  !important;
  }
  :hover {
    border-bottom: 1px solid #F59489  !important;
  }
  :focus {
    border-bottom: 1px solid #F59489  !important;
  }
  @media only screen and (max-width: 600px) {
  width:100vw;
  margin:auto;
    }
  }
  @media only screen and (max-width: 600px) {
  width:100vw;
  margin:auto;
    }
  }
`;

const { Meta } = Card;
const Title = () => (
  <div>
    <Fade delay={500}>
      <h1
        className="mulish-header"
        style={{
          marginTop: "10vw",
          fontWeight: 400,
          fontSize: 40,
          lineHeight: "1.3em",
          marginLeft: "0em",
          color: "white",
        }}
      >
        <strong>It's finally here!</strong> <br />
        <span style={{ fontSize: 26 }}>A South African lifeline.</span>
      </h1>
      <br />

      <p
        style={{
          paddingLeft: window.isPhone ? "1em" : "",
          paddingRight: window.isPhone ? "1em" : "",
          margin: "auto",
          marginTop: "-1em",
          textAlign: "center",
          fontWeight: 600,
          fontSize: 18,
          lineHeight: "1.6em",
          marginLeft: "0em",
          color: "white",
        }}
      >
        A Wealth Creation System allows the South African general public to
        build their own businesses or create a 2nd income stream.
        <br />
        The beauty of it is we don't have to sell anything.
        <br />
        Like to know more Click below
        <br />
        <br />
        <Link to="./duepoint-intro">
          <Button
            className="button-hover-1"
            style={{
              borderRadius: 50,
              fontWeight: 600,
              height: "3.5em",
              width: "14.5em",
              fontSize: "16px",
              marginLeft: window.isPhone ? "" : "-2em",
              backgroundColor: "rgba(255, 255, 255, 0.934)",
              color: "#F79489",
              border: "none",
              marginTop: "2em",
            }}
            type="primary"
            htmlType="submit"
          >
            Start Your Journey
            <img
              style={{
                marginLeft: "1.5em",
                width: "2.5em",
                transform: "rotate(-90deg)",
              }}
              src={ArrowBottomIcon}
            ></img>
          </Button>
        </Link>
      </p>
    </Fade>
  </div>
);
const Image = () => (
  <img
    className="home-img"
    style={{
      margin: "auto",
      marginTop: "4em",
      textAlign: "center",
      width: "100vw",
    }}
    src={HomeImage}
  ></img>
);
const AboutHeader = () => (
  <div id="about">
    {/* <p
        className="Mulish"
        style={{
          fontWeight: 500,
          fontSize: "18px",
          marginBottom: "-0em",
          color: "rgb(214, 54, 41)",
        }}
      >
        <img
          className="hori-line"
          style={{ marginTop: "-0.2em" }}
          src={HoriLine}
        ></img>
        {"  "} About Us
      </p>
      <p
        className="Mulish-center"
        style={{
          fontWeight: 500,
          fontSize: "42px",
          marginBottom: "-0em",
          lineHeight: 1.3,
          paddingRight: "3em",
        }}
      >
        We aim to grow a business that is cost efficient and beneficial for our
        clientele.
      </p> */}
  </div>
);

const AboutHeader1 = () => (
  <div
    style={{
      paddingTop: "2em",
      margin: "auto",
      textAlign: "left",
      paddingLeft: window.isPhone ? "2em" : "",
      paddingRight: window.isPhone ? "2em" : "",
    }}
  >
    <Fade delay={500}>
      <p
        className="Mulish"
        style={{
          fontFamily: "Montserrat",
          margin: "auto",
          fontWeight: 600,
          fontSize: window.isPhone ? "25px" : "35px",
          lineHeight: "1.2em",
          textAlign: window.isPhone ? "center" : "",
          textTransform: "uppercase",
        }}
      >
        Visualize<span style={{ color: "#F79489" }}> your ideas</span> with us
      </p>

      <p
        className="Mulish-center"
        style={{
          fontWeight: 300,
          fontSize: "16px",
          marginTop: "1em",
          marginBottom: "-0em",
          lineHeight: 1.3,
          paddingRight: "3em",
          textAlign: window.isPhone ? "center" : "",
          fontFamily: "Poppins",
        }}
      >
        <strong>
          We help entrepreneurs create the lifestyle of their dreams.
        </strong>
        <br />
        <br />
      </p>
    </Fade>
    <p
      className="Mulish"
      style={{
        fontSize: 15,
        fontWeight: 300,
        marginTop: "1.5rem",
        color: "black",
        fontFamily: "Poppins",
      }}
    >
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        accordion
        defaultActiveKey={["3"]}
        onChange={onChange}
      >
        <Panel header="The Duepoint Wealth Creation System" key="1">
          <img
            style={{ marginBottom: "1em", width: window.isPhone ? "100%" : "" }}
            src={DuePoint}
          ></img>
          <p>
            I have had amazing life-changing success with the Duepoint wealth
            creation system.
            <br />
            I was no spring chicken when I started and had no clue about the
            internet and this "social media" business. But I needed to and
            wanted to make this a success. So I did extensive research on free
            seminars and various ways to gather information from a reliable
            consistent source. So I started educating myself and experimenting
            with different ways and platforms to advertise. By trial and error,
            I found my formula along with the routine.
            <br />
            <br />
            This is exactly what I do and how I maintain the growth of my
            business.
            <br />I am dedicated to making this a possibility for you. So please
            feel free to request any additional information you may want to
            know. So let's get you started on your road to
            #financialfreedom/security with the wonderful benefits of the
            business for your child's #future and worries about #job, they will
            have the business you built along with the #growingsalary every
            month to allow them to pursue what they want in life.
          </p>
          {/* <a
            href="https://www.duepoint.net//application/register/application/v3/a8ab54cf-f017-4798-8e87-a2a1e4cbfa1a"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black"
            }}
          >
            Get Started with Duepoint
            <img

              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </a> */}
        </Panel>
        <Panel header="Digital Marketing" key="2">
          <p>
            Ever since humans learned how to trade, marketing has been in place.
            Marketing is all about connecting with your audience at the right
            place and time. With the rise of the Internet, this means that you
            can connect with your audience easily on the internet through
            digital marketing. <br />
            <br />
            What Is Digital Marketing?
            <br />
            Digital marketing comprises all marketing efforts that use the
            internet. These include digital channels such as search engines,
            email, websites, social media, etc., that can be used to connect
            with current and prospective customers.
            <br />
            <br />
            Unlike traditional methods, digital marketing helps you reach a
            larger audience and target prospects who are most likely to buy your
            products and services. Additionally, it is more cost-effective than
            traditional marketing. Plus, it enables you to measure success and
            make marketing changes as you see fit.
            <br />
            <br />
            The key to successful marketing has always been about connecting
            with the target audience in the right place, at the right time.
            Today - when screen time of consumers is at an all-time high - the
            best place to meet them is on the Internet. Businesses worldwide are
            taking advantage of this reality. <br />
            They are using effective digital marketing strategies to ensure
            their marketing efforts are more likely to reach customers and will,
            in turn, lead to significant commercial benefits.
            <br />
          </p>
        </Panel>
        <Panel header="Cut out the middle" key="3">
          <p style={{ marginBottom: 0 }}>
            Major companies are involved with our unique marketing system. It
            offers a win-win situation for everyone involved. The team members
            get their products and services directly from the service providers
            cutting out the middle man and the product & service providers are
            very happy for they only pay marketing costs once a product or
            service is sold.
          </p>
        </Panel>
      </Collapse>
    </p>{" "}
    <Row
      style={{
        maxWidth: "70em",
        margin: "auto",
        // textAlign: "center",
        marginTop: "2em",
      }}
    >
      {/* <Col sm={24} md={24} style={{ width: "100vw" }}>
        <Button className="button-hover-1"
          style={{
            borderRadius: 50,
            fontWeight: 600,
            height: "3.5em",
            width: "12.5em",
            fontSize: "16px",
            paddingLeft: "1.5em",
            backgroundColor: "white",
            border: "1px solid #F79489",
            color: "#F79489",
            marginTop: "0em",

          }}
          type="primary"
          htmlType="submit"
        >
          Sign Up Today
          <img
            style={{ marginLeft: "1.5em", width: "2.5em", transform: "rotate(-90deg)" }}
            src={ArrowBottomIcon}
          ></img>
        </Button>
      </Col> */}
    </Row>
  </div>
);
const AboutHeader2 = () => (
  <div
    style={{
      paddingTop: "1em",
      margin: "auto",
      textAlign: "left",
      paddingLeft: window.isPhone ? "1em" : "",
      paddingRight: window.isPhone ? "1em" : "",
    }}
  >
    <Fade delay={500}>
      <p
        className="Mulish"
        style={{
          fontFamily: "Montserrat",
          margin: "auto",
          fontWeight: 600,
          fontSize: window.isPhone ? "25px" : "35px",
        }}
      >
        <span style={{ color: "#F79489" }}>Wealth Engineer</span>
      </p>
      <p
        className="Mulish-center"
        style={{
          fontWeight: 500,
          color: "black",
          fontSize: "16px",
          marginBottom: "1.5em",
          lineHeight: 1.3,
          fontFamily: "Poppins",
        }}
      >
        My name is Linnette Badenhorst, and I am a Rank 11" DuePoint™ "Wealth
        Engineer™" and an asset-based recurring income specialist who has been
        running this innovative and brand-new business model since February 1,
        2016. The concept is endorsed by some of the largest registered service
        providers in South Africa and has been approved by 9 official regulatory
        authorities. I have been earning a growing income every night since
        February 2016 using the DuePoint™ system and I can prove this on my bank
        statements and my FREE R250 000 car, registered in my own name! <br />
        <br />
        DuePoint™ realized back in 2015 that our economy is going backwards, and
        that people don't have jobs or that they don't earn enough to be able to
        handle the rising cost of living. Extensive research on this matter has
        been done and the DuePoint™ Wealth Creation System was born to address
        this countrywide problem and its producing excellent results! Instead of
        advertising their products in the media and other places, or paying
        brokers, field teams, call centers and agents to market them, DuePoint™
        decided to rather pay their own clients with their traditional marketing
        costs. Through this, the DuePoint Wealth Creation System offers their
        own customers the opportunity to share the information on this unique
        system, building their own unlimited income, using the latest
        technology, and are contracted out of any selling of products! This way
        DuePoint increases its monthly turnover and you as "Wealth Engineer™ "
        get the chance to build an income producing asset for yourself. You
        don't work for DuePoint™, operate your own business as an independent
        contractor, and are in control of your own income! <br />
        <br />
        Online businesses, such as DuePoint™, offer ALL South Africans the
        opportunity to add an extra income to any other income source/business
        and without investing any capital or taking any risk, nor are there any
        restrictions on how much you can earn. It all depends on yourself. It is
        a LEGAL, registered asset that belongs to you and your descendants
        forever, and it does not require special education, expertise or
        knowledge. You only connect prospective candidates with the website and
        then the decision rests with them once they've worked through the
        information! DuePoint™ also provides all the tools, training, and the
        support of a service center for FREE. Get started today, pick a product,
        and earn in the middle of next month your first income! The system is
        easy to handle, it's simple to do and it WORKS! <br />
        <br />
        DuePoint™ is a fully-fledged Financial Services Provider (FSP Number
        50318) and its head office is located at 2 Payne Road, Bryanston,
        Johannesburg. The customer service number is 0100204500. Feel free to
        call them during office hours for inquiries or check their FSP numbers
        or the FSCA's website. <br />
        <br />
        All products have excellent benefits plus 24/7 daily telephonic access
        to a doctor/attorney, as well as excellent household as well as roadside
        assistance service, but the main thing is that by using one or more of
        the 8 DuePoint™ product options, you can create a recurring, risk-free
        income monthly, and this is what most South Africans desperately need.{" "}
        <br />
        <br />
        <strong>FOR EXAMPLE:</strong>
        <br />
        People are already paying for funeral plans at company A/B/C every month
        because they want the cover should they die. With the DuePoint Wealth
        Creation System, you can get the same benefits AND you can create a
        steady, monthly income with it! There are several other product options
        to choose from such as primary health care, life insurance, mechanical
        warranty on your vehicle, etc. All the products and their benefits are
        available on the website. You simply choose a product you need, enjoy
        the benefits, and make money by sharing the information and you have the
        rest of your life to do it! There is more than 115 000 Wealth Engineers
        who have been benefiting from this system since 2016 and DuePoint™ has
        paid out more than R36 million over the period to their own clients! And
        that's in our dwindling economy! DuePoint™ must be doing something
        right!
        <br />
        <br />
        You do not need to purchase any stock, pay anything upfront or explain
        the concept yourself, because everything happens online and through
        DuePoint's™ website and using the latest technology. DuePoint™ does the
        work and teaches you step by step to achieve success and you earn money
        on every successful connection you made from day one! <br />
        <br />
        DuePoint™ also has an excellent incentive program where you can earn
        R1000/R5000/R10000/R15000 "New Achievers/Achievers" bonus (it's like a
        performance bonus) in any calendar month and it is guaranteed! There are
        also FREE overseas, 5-star luxury family holidays, vehicles and huge
        cash bonuses available to EVERYONE who reaches the set criteria as per
        their business plan. Nobody wins anything but works for it.
        <br />
        <br />
        The following message contains a 9-minute video explaining the concept
        with Afrikaans/Zulu text at the bottom ("captions") and is a description
        of how the concept works. <br />
        <br />
        Or go to{" "}
        <a
          style={{ color: "#F79489" }}
          href="https://www.perpetualincome.org"
          target="_blank"
        >
          https://www.duepoint.net/
        </a>{" "}
        and watch any one of the videos explaining the concept. It also contains
        all the relevant information about the 8 product options currently
        available as well as all their licenses and the terms and conditions.
        You are welcome to call me at{" "}
        <span style={{ color: "#F79489" }}>0827835661</span> so I can answer
        your questions. Your reference number is{" "}
        <span style={{ color: "#F79489" }}>W1000000944</span> if you are
        interested in utilizing this unique concept and would like my help and
        support!
        <br />
        <br />
        Epic:{" "}
        <a
          style={{ color: "#F79489" }}
          href="mailto:Linnette.badenhorst@outlook.com"
          target="_blank"
        >
          Linnette.badenhorst@outlook.com
        </a>
        <br />
        Facebook:{" "}
        <a
          style={{ color: "#F79489" }}
          href="https://www.facebook.com/2ndincomestream/?_rdc=1&_rdr"
          target="_blank"
        >
          https://www.facebook.com/2ndincomestream/?_rdc=1&_rdr
        </a>
        <br />
        LinkedIn:{" "}
        <a
          style={{ color: "#F79489" }}
          href="https://www.linkedin.com/groups/9224820/"
          target="_blank"
        >
          https://www.linkedin.com/groups/9224820/
        </a>
        <br />
        Website:{" "}
        <a
          style={{ color: "#F79489" }}
          href="https://www.perpetualincome.org"
          target="_blank"
        >
          https://www.perpetualincome.org
        </a>
        <br />
        <br />
        If I can do it so can you! <br />
        #successminded #business #jobsecurity #financialfreedomjourney #future{" "}
        <br />
        Greetings
        <br />
        Linnette Badenhorst
        <br />
      </p>
    </Fade>
  </div>
);
const AboutParagraph1 = () => (
  <div>
    <img
      className="products-img-1"
      style={{
        margin: "auto",
        width: "40em",
        height: "40em",
        textAlign: "center",
        objectFit: "contain",
      }}
      src={VisualizeImage}
    ></img>
  </div>
);
const AboutParagraph2 = () => (
  <div>
    <img
      className="products-img-1"
      style={{
        margin: "auto",
        width: "40em",
        height: "40em",
        textAlign: "center",
        objectFit: "contain",
      }}
      src={LinnetteImage}
    ></img>
  </div>
);
const MeetTheTeam1 = () => (
  <Card
    style={{
      width: 240,
      margin: "auto",
      padding: "1em",
      border: "none",
      textAlign: "center",
    }}
    cover={
      <img
        alt="example"
        style={{
          borderRadius: "50%",
          margin: "auto",
          height: "18em",
          objectFit: "cover",
        }}
        src={Johan}
      />
    }
  >
    <Meta
      title={
        <span
          className="Mulish"
          style={{
            fontSize: 20,
            marginTop: "0rem",
            // color: "#2F3D54",
            fontStyle: "italic",
          }}
        >
          Johan Botha
        </span>
      }
      description={
        <span
          style={{
            marginTop: "0rem",
            color: "black",
          }}
        >
          Director
        </span>
      }
    />
  </Card>
);
const MeetTheTeam2 = () => (
  <Card
    style={{
      width: 240,
      margin: "auto",
      padding: "1em",
      border: "none",
      textAlign: "center",
    }}
    cover={
      <img
        alt="example"
        style={{
          borderRadius: "50%",
          margin: "auto",
          height: "18em",
          objectFit: "cover",
        }}
        src={Justin}
      />
    }
  >
    <Meta
      title={
        <span
          className="Mulish"
          style={{
            fontSize: 20,
            marginTop: "0rem",
            // color: "#2F3D54",
            fontStyle: "italic",
          }}
        >
          Justin Geldenhuys
        </span>
      }
      description={
        <span
          style={{
            marginTop: "0rem",
            color: "black",
          }}
        >
          Director
        </span>
      }
    />
  </Card>
);
const ProductsHeader = () => (
  <div>
    <Fade delay={500}>
      <h1
        className="Mulish"
        style={{
          fontFamily: "Montserrat",
          margin: "auto",
          marginBottom: "1em",
          fontWeight: 600,
          textAlign: "center",
          lineHeight: "1.2em",
          fontSize: window.isPhone ? "25px" : "35px",
        }}
      >
        Why Choose<span style={{ color: "#F79489" }}> Perpetual Income</span>
      </h1>
      <p
        className="Mulish-center"
        style={{
          fontWeight: 300,
          fontSize: "16px",
          marginTop: "-1em",
          marginBottom: "1em",
          lineHeight: 1.3,

          textAlign: window.isPhone ? "center" : "",
          fontFamily: "Poppins",
          textAlign: "center",
        }}
      >
        I have had life-changing success using this system to view my story{" "}
        <a style={{ color: "rgb(247, 148, 137)" }} href="/#my-story">
          click here
        </a>
        .
      </p>
    </Fade>
  </div>
);
const ProductsHeader2 = () => (
  <div>
    <h1
      className="Mulish"
      style={{
        margin: "auto",
        fontWeight: 500,
        fontSize: window.isPhone ? "25px" : "42px",
      }}
    >
      Meet The Team
    </h1>
    <div
      style={{
        paddingTop: "0.5em",
        margin: "auto",
        width: "2em",
        borderBottom: "2px solid black",
      }}
    ></div>
    {/* <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        Our product offerings go way beyond amazing product manufacturing. We
        pride ourselves on supplying as well as maintaining professional
        customer service.
      </p> */}
  </div>
);
const ProductsContent = () => (
  <Fade delay={500}>
    <Row style={{ margin: window.isPhone ? "1em" : "3em", marginTop: "2em" }}>
      <Col sm={0} md={1}></Col>
      <Col
        sm={24}
        md={10}
        className="card-hover"
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          border: "5px solid white",
          transition: "0.2s",
          borderRadius: "1em",
          margin: "auto",
        }}
      >
        <Card
          style={{
            backgroundColor: "transparent",
            marginTop: "0.5em",
            marginBottom: "0.5em",
            // padding: "1em",
            borderRadius: "0em",
            border: "none",

            textAlign: window.isPhone ? "center" : "center",
            fontSize: 40,
            color: "#2F3D54",
          }}
          // hoverable
          cover={
            <img
              style={{
                width: "1.3em",
                margin: window.isPhone ? "auto" : "auto",
              }}
              src={ProIcon}
            ></img>
          }
        >
          <Meta
            title={
              <span>
                <p
                  className="Mulish"
                  style={{
                    fontSize: 18,
                    marginTop: "0.8em",
                    lineHeight: "1.3em",
                  }}
                >
                  DuePoint Wealth creation system
                </p>
              </span>
            }
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 14,
                    marginTop: "-0.5rem",
                    marginBottom: "0em",
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                >
                  In-depth look at the company that has offered me a wealth
                  creation system that saved my family's future and gave me the
                  tools to create my own business.
                </p>{" "}
              </span>
            }
          />
          <a
            href="/#about-us"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black",
            }}
          >
            More Info
            <img
              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </a>
        </Card>
      </Col>
      <Col sm={0} md={1}></Col>
      <Col
        sm={24}
        md={10}
        className="card-hover"
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          border: "5px solid white",
          transition: "0.2s",
          borderRadius: "1em",
          margin: "auto",
        }}
      >
        <Card
          style={{
            backgroundColor: "transparent",
            marginTop: "0.5em",
            marginBottom: "0.5em",
            // padding: "1em",
            borderRadius: "0em",
            border: "none",
            textAlign: window.isPhone ? "center" : "center",
            fontSize: 40,
            color: "#2F3D54",
          }}
          // hoverable
          cover={
            <img
              style={{
                width: "1.3em",
                margin: window.isPhone ? "auto" : "auto",
              }}
              src={ProIcon1}
            ></img>
          }
        >
          <Meta
            title={
              <span>
                <p
                  className="Mulish"
                  style={{
                    fontSize: 18,
                    marginTop: "0.8em",
                  }}
                >
                  My road to financial security
                </p>
              </span>
            }
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 14,
                    marginTop: "-0.5rem",
                    marginBottom: "0em",
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                >
                  This is my own personal testimony of using this system and how
                  I made it a success.
                </p>{" "}
              </span>
            }
          />
          <a
            href="/#about-us"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black",
            }}
          >
            More Info
            <img
              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </a>
          {/* <Link
            to="/"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black"
            }}
          >
            Sign Up
            <img

              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </Link> */}
        </Card>
      </Col>
      <Col sm={0} md={1}></Col>
      <Col
        sm={24}
        md={6}
        className="card-hover"
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          border: "5px solid white",
          transition: "0.2s",
          borderRadius: "1em",
          margin: "auto",
        }}
      >
        <Card
          style={{
            backgroundColor: "transparent",
            marginTop: "0.5em",
            marginBottom: "0.5em",
            // padding: "1em",
            borderRadius: "0em",
            border: "none",
            textAlign: window.isPhone ? "center" : "center",
            fontSize: 40,
            color: "#2F3D54",
          }}
          // hoverable
          cover={
            <img
              style={{
                width: "1.3em",
                margin: window.isPhone ? "auto" : "auto",
              }}
              src={ProIcon2}
            ></img>
          }
        >
          <Meta
            title={
              <span>
                <p
                  className="Mulish"
                  style={{
                    fontSize: 18,
                    marginTop: "0.8em",
                  }}
                >
                  My formula
                </p>
              </span>
            }
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 14,
                    marginTop: "-0.5rem",
                    marginBottom: "0em",
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                >
                  A step by Step routine I follow to ensure my business is
                  always growing.
                </p>{" "}
              </span>
            }
          />
          <a
            href="/#tidbits"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black",
            }}
          >
            Webinar Tidbits
            <img
              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </a>
        </Card>
      </Col>
      <Col
        sm={24}
        md={6}
        className="card-hover"
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          border: "5px solid white",
          transition: "0.2s",
          borderRadius: "1em",
          margin: "auto",
        }}
      >
        <Card
          style={{
            backgroundColor: "transparent",
            marginTop: "0.5em",
            marginBottom: "0.5em",
            // padding: "1em",
            borderRadius: "0em",
            border: "none",
            textAlign: window.isPhone ? "center" : "center",
            fontSize: 40,
            color: "#2F3D54",
          }}
          // hoverable
          cover={
            <img
              style={{
                width: "1.3em",
                margin: window.isPhone ? "auto" : "auto",
              }}
              src={ProIcon3}
            ></img>
          }
        >
          <Meta
            title={
              <span>
                <p
                  className="Mulish"
                  style={{
                    fontSize: 18,
                    marginTop: "0.8em",
                  }}
                >
                  Testimonials
                </p>
              </span>
            }
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 14,
                    marginTop: "-0.5rem",
                    marginBottom: "0em",
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                >
                  Find out how other wealth engineers have found success using
                  this system.
                </p>{" "}
              </span>
            }
          />
          <a
            href="/#about-us"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black",
            }}
          >
            More Info
            <img
              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </a>
          {/* <Link
            to="/"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black"
            }}
          >
            Get Started
            <img

              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </Link> */}
        </Card>
      </Col>
      <Col
        sm={24}
        md={6}
        className="card-hover"
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          border: "5px solid white",
          transition: "0.2s",
          borderRadius: "1em",
          margin: "auto",
        }}
      >
        <Card
          style={{
            backgroundColor: "transparent",
            marginTop: "0.5em",
            marginBottom: "0.5em",
            // padding: "1em",
            borderRadius: "0em",
            border: "none",
            textAlign: window.isPhone ? "center" : "center",
            fontSize: 40,
            color: "#2F3D54",
          }}
          // hoverable
          cover={
            <img
              style={{
                width: "1.3em",
                margin: window.isPhone ? "auto" : "auto",
              }}
              src={ProIcon3}
            ></img>
          }
        >
          <Meta
            title={
              <span>
                <p
                  className="Mulish"
                  style={{
                    fontSize: 18,
                    marginTop: "0.8em",
                  }}
                >
                  Gallery
                </p>
              </span>
            }
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 14,
                    marginTop: "-0.5rem",
                    marginBottom: "0em",
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                >
                  Look at some of the many company vacations and lifestyle I
                  have been blessed to now live.
                </p>{" "}
              </span>
            }
          />
          <a
            href="/#about-us"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black",
            }}
          >
            More Info
            <img
              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </a>
          {/* <Link
            to="/"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black"
            }}
          >
            Get Started
            <img

              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </Link> */}
        </Card>
      </Col>
    </Row>
  </Fade>
);
const CertHeader = () => (
  <div id="tidbits">
    <Fade delay={500}>
      <h1
        className="Mulish"
        style={{
          fontFamily: "Montserrat",
          margin: "auto",
          fontWeight: 600,
          fontSize: window.isPhone ? "25px" : "35px",
          textTransform: "uppercase",
        }}
      >
        What is a <span style={{ color: "#F79489" }}>Wealth Engineer </span>
      </h1>

      {/* <p
        className="Mulish"
        style={{
          fontWeight: 300,
          fontSize: "15px",
          marginTop: "1em",
          marginBottom: "2em",
          lineHeight: 1.3,
          // paddingRight: window.isPhone ? "" : "3em",
          fontFamily: "Poppins"
        }}
      >
        Empowering every person and every business to achieve more, designed to get you better results.
      </p>{" "} */}
    </Fade>
  </div>
);
const CertHeader1 = () => (
  <div id="tidbits">
    <Fade delay={500}>
      <h1
        className="Mulish"
        style={{
          fontFamily: "Montserrat",
          margin: "auto",
          fontWeight: 600,
          fontSize: window.isPhone ? "25px" : "35px",
          textTransform: "uppercase",
        }}
      >
        As Seen <span style={{ color: "#F79489" }}>on TV</span>
      </h1>
      <p
        className="Mulish"
        style={{
          fontWeight: 300,
          fontSize: "15px",
          marginTop: "1em",
          marginBottom: "2em",
          lineHeight: 1.3,
          // paddingRight: window.isPhone ? "" : "3em",
          fontFamily: "Poppins",
        }}
      >
        Taking back control of your financial future!
      </p>{" "}
    </Fade>
  </div>
);
const CertParagraph = () => (
  <div>
    <p
      className="Mulish"
      style={{
        fontSize: 18,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Our company endeavors to keep up to date with the increasing number of
      regulations and need for operational transparency in the industry. We
      pride ourselves in sending out quality controlled work welded only by
      fully certified staff.
    </p>{" "}
  </div>
);
const GalleryHeader = () => (
  <div>
    <img
      className="gallery-banner-img"
      style={{ margin: "auto", textAlign: "center" }}
      src={GalleryHome}
    ></img>{" "}
  </div>
);
const GalleryParagraph = () => (
  <div>
    <img
      className="gallery-banner-img"
      style={{ margin: "auto", textAlign: "center" }}
      src={GalleryHome}
    ></img>{" "}
  </div>
);
const ContactHeader = () => (
  <div id="contact-us">
    <Fade delay={500}>
      <h1
        className="Mulish"
        style={{
          fontFamily: "Montserrat",
          margin: "auto",
          fontWeight: 700,
          fontSize: window.isPhone ? "25px" : "35px",
          textTransform: "uppercase",
        }}
      >
        Get in Touch
      </h1>
      <p
        className="Mulish"
        style={{
          fontWeight: 300,
          fontSize: "14px",
          marginTop: "0.5em",
          marginBottom: "2em",
          lineHeight: 1.3,
          color: "#737373",
        }}
      >
        If you want to live this lifestyle, join the revolution and reach your
        full potential.
      </p>{" "}
    </Fade>
  </div>
);
//const ContactForm = () => (
//  <Form
//    onFinish={this.handleSubmit}
//    initialValues={{
//      remember: true,
//    }}
//    name="wrap"
//    labelCol={{ flex: "110px" }}
//    labelAlign="left"
//    labelWrap
//    wrapperCol={{ flex: 1 }}
//    colon={false}
//  >
//    <Row style={{}}>
//      <Col sm={24} md={11}>
//        <Form.Item name="name" rules={[{ required: true }]}>
//          <StyledInput placeholder="Company Name" />
//        </Form.Item>
//      </Col>
//      <Col sm={0} md={2}></Col>
//      <Col sm={24} md={11}>
//        <Form.Item name="email" rules={[{ required: true }]}>
//          <StyledInput placeholder="Company Email" />
//        </Form.Item>
//      </Col>
//      <Col sm={24} md={24}>
//        <Form.Item name="message" rules={[{ required: true }]}>
//          <StyledInput placeholder="Message" />
//        </Form.Item>
//      </Col>
//    </Row>
//    <Form.Item>
//      <Button
//        style={{
//          borderRadius: 50,
//          fontWeight: 500,
//          height: "3em",
//          width: "9em",
//          fontSize: "17px",
//          backgroundColor: "#D63629",
//          border: "black",
//          marginTop: "1em",
//        }}
//        type="primary"
//        htmlType="submit"
//      >
//        Send
//      </Button>
//    </Form.Item>
//  </Form>
//);
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successModal: false,
      visiblePopup: false,
    };
    this.changeValue = this.changeValue.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }
  handleTabClick = key => {
    this.props.history.push(`/${key}`); // < == router router v4
  };
  componentDidMount() {
    document.title = "Perpetual Income";
    document.documentElement.scrollTop = 0;
  }
  onChange = e => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = value => {
    this.setState({
      inputValue: value,
    });
  };
  showModal = () => {
    this.setState({
      visiblePopup: true,
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };
  handleClickDemo(form) {
    this.setState({
      FormChangeDemo: form,
    });
  }
  handleClickCall(form) {
    this.setState({
      FormChangeCall: form,
    });
  }
  showModalDrone = () => {
    this.setState({
      visiblePopupDrone: true,
    });
  };
  handleOk1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };

  handleCancel1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };
  handleSubmit = values => {
    this.onFinish(values);
  };
  changeValue = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };

  onFinish = values => {
    const MSG = {
      to: ["linnette.badenhorst@outlook.com"],
      from: "info@perpetualincome.co.za",
      //to: ["ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New contact request",
      text: " ",
      html: `

      <style type="text/css">
        body, #bodyTable{background - color:white}
        #emailHeader{background - color:white}
        #emailBody{background - color:#FFFFFF; border:1px solid #CCCCCC;}
        #emailFooter{background - color:#E1E1E1;}
      </style>
      <body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
        <center style="background-color:#E1E1E1;">
          <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
            <tr>
              <td align="center" valign="top" id="bodyCell">
                <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
                  <!-- HEADER ROW // -->
                  <tr>
                    <td align="center" valign="top">
                      <!-- CENTERING TABLE // -->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td align="center" valign="top">
                            <!-- FLEXIBLE CONTAINER // -->
                            <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                              <tr>
                                <td valign="top" width="500" class="flexibleContainerCell">

                                  <!-- CONTENT TABLE // -->
                                  <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                                    <tr>

                                      <td align="right" valign="middle" class="flexibleContainerBox">
                                        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                          <tr>
                                            <td align="left" class="textContent">
                                              <!-- CONTENT // -->

                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <!-- // FLEXIBLE CONTAINER -->
                          </td>
                        </tr>
                      </table>
                      <!-- // CENTERING TABLE -->
                    </td>
                  </tr>
                  <!-- // END -->
                </table>
                <!-- EMAIL BODY // -->
                <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
                  <!-- MODULE ROW // -->
                  <tr>
                    <td align="center" valign="top">
                      <!-- CENTERING TABLE // -->
                      <table border="0" cellpadding="0" cellspacing="0" style="color:#FFFFFF;" bgcolor="white">
                        <tr>
                          <td align="center" valign="top">
                            <!-- FLEXIBLE CONTAINER // -->

                            <table border="0" cellpadding="0" cellspacing="0" width="500" class="flexibleContainer">
                              <tr>
                                <td align="center" valign="top" width="500" class="flexibleContainerCell">

                                  <!-- CONTENT TABLE // -->

                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                                    <td align="center" valign="top" width="500" class="flexibleContainerCell  ">
                                      <img alt="" src="https://i.ibb.co/2dRzKr7/Group-10131.png" width="300" height="100" style="display: block; width:348px; max-width: 355px;; min-width: 189px;;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                                        <td align="center" valign="top" class="textContent">
                                          <h1 style="color:black;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                                          <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color:black;line-height:135%;">You have received a new contact request from <br />Perpetual Income website</h2>
                                        </td>
                                      </tr>
                                      </table>
                                      <!-- // CONTENT TABLE -->
                                    </td>
                                  </tr>
                                </table>
                                <!-- // FLEXIBLE CONTAINER -->
                              </td>
                            </tr>
                          </table>
                          <!-- // CENTERING TABLE -->
                        </td>
                      </tr>
                      <!-- MODULE ROW // -->
                      <tr mc:hideable>
                        <td align="center" valign="top">
                          <!-- CENTERING TABLE // -->
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
                            <tr>
                              <td align="center" valign="top">
                                <!-- FLEXIBLE CONTAINER // -->
                                <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                                  <tr>
                                    <td valign="top" width="500" class="flexibleContainerCell">
                                      <!-- CONTENT TABLE // -->
                                      <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                                        <tr>
                                          <td align="center" valign="top" class="flexibleContainerBox">
                                            <table border="0" cellspacing="0" cellpadding="0" >

                                              <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                                                <td class="role"><br /><br /><br /><br /><br />
                                                  <strong> Full Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                                                  <strong> Number </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.number}<br />
                                                  <strong> Email </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.email}
                                               
                                            
                                                  <br /><br /><br />
                                                </td>
                                              </tr>
                                              <table>
                                              </td>
                                            </tr>
                                          </table>
                                          <!-- // CONTENT TABLE -->

                                        </td>
                                      </tr>
                                    </table>
                                    <!-- // FLEXIBLE CONTAINER -->
                                  </td>
                                </tr>
                              </table>
                              <!-- // CENTERING TABLE -->
                            </td>
                          </tr>
                          <!-- // MODULE ROW -->


                          <!-- MODULE ROW // -->

                          <table border="0" cellpadding="0" cellspacing="0" width="100%" style="color:#FFFFFF;" bgcolor="white" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
                            <tr>
                              <td align="center" valign="top">

                                <table border="0" cellpadding="0" cellspacing="0" width="500" class="flexibleContainer">
                                  <tr>
                                    <td align="center" valign="top" width="500" class="flexibleContainerCell">


                                      <table border="0" cellpadding="30" cellspacing="0" width="100%">


                                        <tr>
                                          <td align="center" valign="top" class="textContent">

                                            <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2022@Perpetual Income</h2>

                                          </td>
                                        </tr>
                                      </table>
                                      <!-- // CONTENT TABLE -->

                                    </td>
                                  </tr>
                                </table>
                                <!-- // FLEXIBLE CONTAINER -->
                              </td>
                            </tr>
                          </table>
                          `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async res => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        name: "",
        email: "",
        number: "",
        message: "",
      });
    });
  };

  render() {
    return (
      <div>
        <Header />
        <Row
          style={{
            maxWidth: "70em",
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Col sm={24} md={24} style={{ width: "100vw", marginTop: "8em" }}>
            <CertHeader />
          </Col>
        </Row>

        <div>
          <Row
            className="about-row"
            style={{
              margin: "auto",
              marginTop: "2em",
              maxWidth: "110em",
              padding: window.isPhone ? "1em" : "4em",
              paddingTop: 0,
            }}
          >
            <Col sm={24} md={24} style={{ width: "100%" }}>
              <div>
                <ReactPlayer width="100%" url="https://youtu.be/VgxnjwesIAg" />
              </div>
            </Col>
            <Col sm={24} md={24} style={{ marginTop: "3em" }}>
              <AboutHeader2 />
              <Button
                className="button-hover-1"
                onClick={this.showModal}
                style={{
                  borderRadius: 50,
                  fontWeight: 600,
                  height: "3.5em",
                  width: "15.5em",
                  fontSize: "16px",
                  paddingLeft: "1.5em",
                  backgroundColor: "white",
                  border: "1px solid #F79489",
                  color: "#F79489",
                  marginTop: "1em",
                }}
                type="primary"
                htmlType="submit"
              >
                Join Duepoint Today
                <img
                  style={{
                    marginLeft: "1.5em",
                    width: "2.5em",
                    transform: "rotate(-90deg)",
                  }}
                  src={ArrowBottomIcon}
                ></img>
              </Button>
            </Col>
          </Row>
          <Modal
            title="Basic Modal"
            visible={this.state.visiblePopup}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            style={{ textAlign: "center" }}
          >
            <img
              src={DuePoint}
              alt="Logo"
              style={{ width: "100%", marginTop: "3em" }}
            />
            <h3
              style={{
                marginTop: "2em",
                color: "black",
                textAlign: "center",
              }}
            >
              Create monthly income with DuePoint and{" "}
              <span style={{ color: "#F79489" }}>Perpetual Income.</span>
              <br />
              <br />
              Your Lead Wealth Engineers are Linnette & Frikkie Badenhorst and
              their Wealth Engineer number to use to be part of the fastest
              growing DuePoint Channel is:
              <br />
              <br />
              <div style={{ border: "dotted 1px #F79489", padding: "1em" }}>
                Wealth Engineer number :{" "}
                <span style={{ color: "#F79489" }}>W1000000944</span>
              </div>
              <br />
              <span style={{ color: "black" }}>
                Click here to start your online application
              </span>{" "}
              <br />
            </h3>{" "}
            <br />
            <a href="https://www.duepoint.net/" target="_blank">
              <Button
                className="button-hover-1"
                onClick={this.showModal}
                style={{
                  borderRadius: 50,
                  fontWeight: 600,
                  height: "3.5em",
                  width: "15.5em",
                  fontSize: "16px",
                  paddingLeft: "1.5em",
                  backgroundColor: "white",
                  border: "1px solid #F79489",
                  color: "#F79489",
                  marginTop: "0em",
                }}
                type="primary"
                htmlType="submit"
              >
                Join Duepoint Today
                <img
                  style={{
                    marginLeft: "1.5em",
                    width: "2.5em",
                    transform: "rotate(-90deg)",
                  }}
                  src={ArrowBottomIcon}
                ></img>
              </Button>
            </a>
          </Modal>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Home;
