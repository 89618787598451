import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import DuePointIntro from "./pages/DuePointIntro";
import WealthEng from "./pages/WealthEng";
import Gallery from "./pages/Gallery";
import MyStory from "./pages/MyStory";
import TeamAwards from "./pages/TeamAwards";
import DueProducts from "./pages/DueProducts";
import CertAndLic from "./pages/CertAndLic";
import reportWebVitals from "./reportWebVitals";
import Layout from "./components/Layout";

import NoPage from "./pages/NoPage";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter
    // basename="perpetualincome"
    >
      <Routes>
        <Route path="/" element={<Layout />}></Route>
        <Route path="/duepoint-intro" element={<DuePointIntro />}></Route>
        <Route path="/wealth-engineer" element={<WealthEng />}></Route>
        <Route
          path="/certifications-licencing"
          element={<CertAndLic />}
        ></Route>
        <Route path="/duepoint-products" element={<DueProducts />}></Route>
        <Route path="/gallery" element={<Gallery />}></Route>
        <Route path="/my-story" element={<MyStory />}></Route>
        <Route path="/team-awards" element={<TeamAwards />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
