import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Collapse,
  Modal,
  Rate,
} from "antd";
import styled from "styled-components";
import HomeImage from "../static/Home/home-img.png";

import DuePoint from "../static/Home/duepoint.png";
import ProductImage1 from "../static/Home/product-image-1.png";
import VisualizeImage from "../static/Home/visualize-img.png";
import LinnetteImage from "../static/Home/linnette-img.png";
import Justin from "../static/Home/Justin.jpg";
import VideoBack from "../static/Home/video-back.png";
import Johan from "../static/Home/Johan.jpg";
import ProIcon from "../static/Home/pro-icon.svg";
import ProIcon1 from "../static/Home/pro-icon-1.svg";
import ProIcon2 from "../static/Home/pro-icon-2.svg";
import ProIcon3 from "../static/Home/pro-icon-3.svg";
import ArrowBottomIcon from "../static/Home/arrow-bottom-icon.svg";
import GalleryHome from "../static/Home/bookshelf.png";
import GalleryHomeBack from "../static/Home/bookcase.png";
import GalleryHomeBackMobile from "../static/Home/bookcase-mobile.png";
import { Modal as SuccessModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import WingLeft from "../static/Home/wing-left.png";
import WingRight from "../static/Home/wing-right.png";
import Eifel from "../static/Home/eifel-img.png";
import LogoModal from "../static/Home/main-logo.png";
import EifelMobile from "../static/Home/eifel-img-mobile.png";
import HoriLine from "../static/Home/hori-line.svg";
import { CaretRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { CgArrowLongRight } from "react-icons/cg";
import { FaAward } from "react-icons/fa";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const onChange = key => {
  console.log(key);
};
const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const settingsTestimonials = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const { Panel } = Collapse;
const StyledInput = styled(Input)`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 1.2em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  height: 60px !important;
  padding: 4px 0px ;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 1px solid #F59489 !important;
  border-bottom: 1px solid #F59489 !important;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500 !important;
  color: #F59489 !important;
  box-shadow:none !important;
  :placeholder {
    color: black !important;
  }

  :hover {
    border-bottom: 1px solid #F59489  !important;
  }
  :active{
    border-bottom: 1px solid #F59489  !important;
  }
  :hover {
    border-bottom: 1px solid #F59489  !important;
  }
  :focus {
    border-bottom: 1px solid #F59489  !important;
  }
  @media only screen and (max-width: 600px) {
  width:100vw;
  margin:auto;
    }
  }
  @media only screen and (max-width: 600px) {
  width:100vw;
  margin:auto;
    }
  }
`;

const { Meta } = Card;
const Title = () => (
  <div>
    <Fade delay={500}>
      <h1
        className="mulish-header"
        style={{
          marginTop: "10vw",
          fontWeight: 400,
          fontSize: 40,
          lineHeight: "1.3em",
          marginLeft: "0em",
          color: "white",
        }}
      >
        <strong>It's finally here!</strong> <br />
        <span style={{ fontSize: 26 }}>A South African lifeline.</span>
      </h1>
      <br />

      <p
        style={{
          paddingLeft: window.isPhone ? "1em" : "",
          paddingRight: window.isPhone ? "1em" : "",
          margin: "auto",
          marginTop: "-1em",
          textAlign: "center",
          fontWeight: 600,
          fontSize: 18,
          lineHeight: "1.6em",
          marginLeft: "0em",
          color: "white",
        }}
      >
        A Wealth Creation System allows the South African general public to
        build their own businesses or create a 2nd income stream.
        <br />
        The beauty of it is we don't have to sell anything.
        <br />
        Like to know more Click below
        <br />
        <br />
        <Link to="duepoint-intro">
          <Button
            className="button-hover-1"
            style={{
              borderRadius: 50,
              fontWeight: 600,
              height: "3.5em",
              width: "15em",
              fontSize: "16px",
              marginLeft: window.isPhone ? "" : "-2em",
              backgroundColor: "rgba(255, 255, 255, 0.934)",
              color: "#F79489",
              border: "none",
              marginTop: window.isPhone ? "" : "2em",
            }}
            type="primary"
            htmlType="submit"
          >
            Work with Linnette
            <img
              style={{
                marginLeft: "1.5em",
                width: "2.5em",
                transform: "rotate(-90deg)",
              }}
              src={ArrowBottomIcon}
            ></img>
          </Button>
        </Link>{" "}
        <Link to="team-awards">
          <Button
            className="button-hover-1"
            style={{
              borderRadius: 50,
              fontWeight: 600,
              height: "3.5em",
              width: "11em",
              fontSize: "16px",
              marginLeft: window.isPhone ? "1em" : "1em",
              backgroundColor: "rgba(255, 255, 255, 0.934)",
              color: "#F79489",
              border: "none",
              marginTop: "1em",
            }}
            type="primary"
            htmlType="submit"
          >
            Team Awards
            <FaAward
              style={{
                marginBottom: "-0.2em",
                marginLeft: "0.5em",
                fontSize: "24px",
              }}
              // src={ArrowBottomIcon}
            ></FaAward>
          </Button>
        </Link>
      </p>
    </Fade>
  </div>
);
const Image = () => (
  <img
    className="home-img"
    style={{
      margin: "auto",
      marginTop: "4em",
      textAlign: "center",
      width: "100vw",
    }}
    src={HomeImage}
  ></img>
);
const AboutHeader = () => (
  <div id="about">
    {/* <p
        className="Mulish"
        style={{
          fontWeight: 500,
          fontSize: "18px",
          marginBottom: "-0em",
          color: "rgb(214, 54, 41)",
        }}
      >
        <img
          className="hori-line"
          style={{ marginTop: "-0.2em" }}
          src={HoriLine}
        ></img>
        {"  "} About Us
      </p>
      <p
        className="Mulish-center"
        style={{
          fontWeight: 500,
          fontSize: "42px",
          marginBottom: "-0em",
          lineHeight: 1.3,
          paddingRight: "3em",
        }}
      >
        We aim to grow a business that is cost efficient and beneficial for our
        clientele.
      </p> */}
  </div>
);

const AboutHeader1 = () => (
  <div
    style={{
      margin: "auto",
      textAlign: "left",
      paddingLeft: window.isPhone ? "2em" : "",
      paddingRight: window.isPhone ? "2em" : "",
    }}
  >
    <Fade delay={500}>
      <p
        className="Mulish"
        style={{
          fontFamily: "Montserrat",
          margin: "auto",
          marginTop: "3em",
          fontWeight: 600,
          fontSize: window.isPhone ? "15px" : "25px",
          lineHeight: "1.2em",
          textAlign: window.isPhone ? "center" : "",
        }}
      >
        Start your journey to a brighter more <br />
        <span style={{ color: "#F79489" }}>financially stable future</span>
      </p>

      <p
        className="Mulish-center"
        style={{
          fontWeight: 300,
          fontSize: "16px",
          marginTop: "1em",
          marginBottom: "-0em",
          lineHeight: 1.3,
          paddingRight: "3em",
          textAlign: window.isPhone ? "center" : "",
          fontFamily: "Poppins",
        }}
      >
        <strong>
          We help entrepreneurs create the lifestyle of their dreams.
        </strong>
        <br />
      </p>
    </Fade>
    {/* <p
      className="Mulish"
      style={{
        fontSize: 15,
        fontWeight: 300,
        marginTop: "1.5rem",
        color: "black",
        fontFamily: "Poppins"
      }}
    >
      <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} accordion defaultActiveKey={['3']} onChange={onChange}>
        <Panel header="The Duepoint Wealth Creation System" key="1">
          <img
            style={{ marginBottom: "1em", width: window.isPhone ? "100%" : "" }}
            src={DuePoint}
          ></img>
          <p>I have had amazing life-changing success with the Duepoint wealth creation system.<br />
            I was no spring chicken when I started and had no clue about the internet and this "social media" business.
            But I needed to and wanted to make this a success. So I did extensive research on free seminars and various ways to gather information from a reliable consistent source.
            So I started educating myself and experimenting with different ways and platforms to advertise. By trial and error, I found my formula along with the routine.<br /><br />

            This is exactly what I do and how I maintain the growth of my business.<br />
            I am dedicated to making this a possibility for you. So please feel free to request any additional information you may want to know.
            So let's get you started on your road to #financialfreedom/security with the wonderful benefits of the business for your child's #future and worries about #job, they will have the business you built along with the #growingsalary every month to allow them to pursue what they want in life.
          </p>
          <Link to="duepoint-intro"

            style={{
              borderRadius: 50,
              fontWeight: 600,
              height: "3.5em",
              width: "14.5em",
              fontSize: "16px",

              backgroundColor: "rgba(255, 255, 255, 0.934)",
              color: "#F79489",
              border: "none",
              marginTop: "2em",

            }}
          >
            Start Your Journey
            <img
              style={{ marginLeft: "0.5em", width: "1.5em", transform: "rotate(-90deg)" }}
              src={ArrowBottomIcon}
            ></img>

          </Link>
 
        </Panel>
        <Panel header="My Road to Financial Security" key="2">
          <p>

            This is my own personal testimony of using this system and how I made it a success.<br />
          </p>
        </Panel>
        <Panel header="My Formula" key="3">
          <p style={{ marginBottom: 0 }}>A step by step routine I follow to ensure my business is always growing.</p>
        </Panel>
      </Collapse>

    </p>{ */}
    <Row
      style={{
        maxWidth: "70em",
        margin: "auto",
        // textAlign: "center",
        marginTop: "2em",
      }}
    >
      {/* <Col sm={24} md={24} style={{ width: "100vw" }}>
        <Button className="button-hover-1"
          style={{
            borderRadius: 50,
            fontWeight: 600,
            height: "3.5em",
            width: "12.5em",
            fontSize: "16px",
            paddingLeft: "1.5em",
            backgroundColor: "white",
            border: "1px solid #F79489",
            color: "#F79489",
            marginTop: "0em",

          }}
          type="primary"
          htmlType="submit"
        >
          Sign Up Today
          <img
            style={{ marginLeft: "1.5em", width: "2.5em", transform: "rotate(-90deg)" }}
            src={ArrowBottomIcon}
          ></img>
        </Button>
      </Col> */}
    </Row>
  </div>
);
const AboutHeader2 = () => (
  <div
    id="my-story"
    style={{
      paddingTop: "4em",
      margin: "auto",
      textAlign: "left",
      paddingLeft: window.isPhone ? "2em" : "",
      paddingRight: window.isPhone ? "2em" : "",
    }}
  >
    <Fade delay={500}>
      <p
        className="Mulish"
        style={{
          fontFamily: "Montserrat",
          margin: "auto",
          fontWeight: 600,
          fontSize: window.isPhone ? "25px" : "35px",
          textTransform: "uppercase",
        }}
      >
        My <span style={{ color: "#F79489" }}>story</span>
      </p>
      <p
        className="Mulish-center"
        style={{
          fontWeight: 500,
          color: "black",
          fontSize: "16px",
          marginBottom: "1.5em",
          lineHeight: 1.3,

          fontFamily: "Poppins",
        }}
      >
        My name is<span style={{ color: "#F79489" }}> Linnette Badenhorst</span>{" "}
        Asset income specialist
      </p>

      <p
        className="Mulish-center"
        style={{
          fontWeight: 500,
          fontSize: "14px",
          marginBottom: "-0em",
          lineHeight: 1.6,
          paddingRight: "3em",
          fontFamily: "Poppins",
          height: "25em",
          overflow: "scroll",

          overflowX: "hidden",
        }}
      >
        I am from Vanderbijlpark, South Africa. I was one of those people who
        worked hard all my life and never achieved anything significant, but I
        was always helping others who were less fortunate in the community.
        After looking after my children and getting them through school, I
        started working at the Community Chest of Vanderbijlpark as a PRO, Child
        Welfare and Destinata School for children with learning disabilities and
        ended up working at Dr. Malan Secondary School as a marketing manager
        and was retrenched in 1997. <br />
        <br />
        My husband was retrenched in 1998 as the Customer complaint Manger at
        Iscor, Vanderbijlpark .<br />
        <br />
        Our financial advisor assured us that everything is going to be fine and
        that we will receive a 10% annual increase in our income, etc., etc.
        According to her, life was going to be good, and our golden years were
        sorted. Then Stock Market crashed in 2008 and suddenly we lost almost
        our entire retrenchment package and we could do nothing about it. We
        were both job hunting for 22 years, but we could not find one, because
        once you turn 50 nobody wants to hire you anymore. I started an art
        studio and used my skills in stained glass, wood carving and pottery to
        keep the wolf from the door. <br />
        <br />
        We were in desperate need of a reliable income, and I tried everything
        legal, but nothing really gave us a recurring income. It was all
        activity-based income and I realized that as the years were progressing,
        we were getting older, and we would get to a stage where it will be
        physically impossible to do hard labor. I mean, running an art studio,
        handling commission in stained glass and ceramic products is hard work,
        and manning a stall at the craft markets in the wind and rain takes a
        lot of work and energy. But the main problem was that we did not earn a
        recurring income. No work equaled no money. If the markets were canceled
        due to rain, no income was made, and I still had to pay the stall fees.
        I just cannot imagine the predicament we would be in if we were still
        running the markets when the Covid pandemic struck us. No markets meant
        no income for 5 months! I believe that we would have lost everything if
        that was the case.
        <br />
        <br />
        Back in 2016, I started to use a brand-new marketing concept and within
        my first month I earnt 10 times my product payment and I realized the
        magnitude of this marketing system. But I am a skeptic by nature, and I
        know that it is one thing to make huge promises and another thing to
        make good on them. I simply needed to work with the new management team
        to see if I can trust them and that they will deliver on their promises.
        And they definitely did earn my trust over the years of working with
        them. I loved the fact that we were not selling any products but are
        simply sharing information on the system and then everybody can check it
        out for themselves and see if it is a fit for them or not. No selling!
        No convincing! No recruiting! This suited me and I also realize that
        social media is the perfect platform to do it. <br />
        <br />
        Previously, I tried a network marketing company selling financial
        products for 19 years, worked hard, but nothing spectacular happened. I
        just could not reach the top. I earned about R4000 per month and that
        added to my input from the markets that kept us going. I saw this
        brand-new concept as an extra income to supplement the income from our
        efforts on the markets as well. <br />
        <br />
        Then in July 2017 my friend Phumi, received a FREE car as part of an
        amazing incentive program. Suddenly I realized that I was sitting with
        the answer to our prayers in my hands, but that I was not using it as I
        was too busy with all the other stuff that was not making us money. I
        there and then decided that the next car will be mine! On 18 November
        2019, I received my own FULLY PAID brand-new car of my choice! Within a
        year after my decision to quit all the other activities that were not
        making us money and focused on building my own asset using this
        brand-new marketing model, things changed dramatically! I qualified for
        our first annual 5-Star family holiday abroad in 2018 and 6 months later
        I received an R50 000 cash bonus and another 6 months later a FREE car!
        All that in one and a half years of doing it full-time and I am
        technically disadvantaged. What do you think a person can do with the
        proper skills?
        <br />
        <br />
        In the beginning I was still trying the one-on-one approach as I did for
        19 years, and then I realize that I am just not fit enough to keep on
        doing that for the rest of my life and turned to social media marketing.
        I knew that there are 18 million active people on Facebook in SA alone
        and on LinkedIn there is even more, and I saw this as a huge market, if
        only I could tap into it. I know that 95% of people are also looking for
        an extra income and if I could manage an online business I could get to
        where I want to be. The problem was that I did not know anything about
        social media, and I do not have kids around me to show me the ropes.{" "}
        <br />
        <br />
        At the age of 64, I started to learn about social media marketing. The
        next problem was that I did not have any money to spend on paid courses,
        so, I watched all the free webinars (even at 3:00 in the morning) and
        started to learn how to handle a business online using Facebook,
        LinkedIn and Instagram, Twitter, etc. The main thing was that I applied
        what I had learned, and I believe that this is the reason I am now the
        second highest earner using this system. Imagine if you are young and do
        have the necessary skills, how soon you can exceed my achievements by
        far! Today I am grateful for making that decision and keep working on it
        until I achieved my dreams. <br />
        <br />
        It is the first time in my life that I am earning my annual salary as a
        PRO every month for the returns on my efforts and it is growing every
        month. It is also the first time in my life that I am owning a brand-new
        car (I chose a Suzuki Ertiga GLX) and I did not pay for it. We are in a
        better financial position than we have ever been in our working careers.
        Within a couple of years, we went from unemployed to being self-employed
        and self-sufficient without working for a boss. <br />
        <br />
        When my Great Dane dog got sick and needed a hysterectomy, I could
        afford to pay for it in cash. When my old car broke down, I could pay
        for the repairs without getting a loan (as usual) to do that and when I
        suffered plantar fasciitis, I could pay for the expensive treatment out
        of my pocket as we could not afford a medical aid at that stage. That is
        the difference between surviving and living a lifestyle of choice!
        <br />
        <br />
        I used the R50 000 cash bonus to visit my children and grandchildren,
        who I haven’t seen in 4 years and that was one of my biggest dreams that
        came true. <br />
        <br />
        All this at a very late stage of my life (my husband is turning 74 and I
        am almost 70), we are proof that age is just a number and that you can
        do anything if you are willing to put your mind and effort towards it. I
        really feel privileged as my peers are either deceased or are living
        with the children and we can now live a better life than ever in our
        working careers! It also gives me a reason to get up in the morning, it
        gave me back my dignity (it is a bad place to be in to never have enough
        money to cover your expenses) and we are looking forward to a prosperous
        future here in our beloved South Africa and this system could do the
        same for you.
        <br />
        <br />
        It shows that the only difference between success and failure is the
        stamina to keep on keeping on until you achieve success! Can you imagine
        the change it can make in YOUR life if you are younger than us? Success
        is in your reach, you just have to grab it. <br />
        <br />
        <br />
        <br />
        I am living proof that you are never too old and that old dogs CAN learn
        new tricks!
        <br />
        I can honestly say I am living my DREAM and so can you!
        <br />
      </p>
    </Fade>
  </div>
);
const AboutParagraph1 = () => (
  <div>
    <img
      className="products-img-1"
      style={{
        margin: "auto",
        marginTop: "-1.6em",
        width: "80%",
        height: "40em",
        textAlign: "center",
        objectFit: "contain",
      }}
      src={VisualizeImage}
    ></img>
  </div>
);
const AboutParagraph2 = () => (
  <div>
    <img
      className="products-img-1"
      style={{
        margin: "auto",
        width: "40em",
        height: "40em",
        textAlign: "center",
        objectFit: "contain",
      }}
      src={LinnetteImage}
    ></img>
  </div>
);
const MeetTheTeam1 = () => (
  <Card
    style={{
      width: 240,
      margin: "auto",
      padding: "1em",
      border: "none",
      textAlign: "center",
    }}
    cover={
      <img
        alt="example"
        style={{
          borderRadius: "50%",
          margin: "auto",
          height: "18em",
          objectFit: "cover",
        }}
        src={Johan}
      />
    }
  >
    <Meta
      title={
        <span
          className="Mulish"
          style={{
            fontSize: 20,
            marginTop: "0rem",
            // color: "#2F3D54",
            fontStyle: "italic",
          }}
        >
          Johan Botha
        </span>
      }
      description={
        <span
          style={{
            marginTop: "0rem",
            color: "black",
          }}
        >
          Director
        </span>
      }
    />
  </Card>
);
const MeetTheTeam2 = () => (
  <Card
    style={{
      width: 240,
      margin: "auto",
      padding: "1em",
      border: "none",
      textAlign: "center",
    }}
    cover={
      <img
        alt="example"
        style={{
          borderRadius: "50%",
          margin: "auto",
          height: "18em",
          objectFit: "cover",
        }}
        src={Justin}
      />
    }
  >
    <Meta
      title={
        <span
          className="Mulish"
          style={{
            fontSize: 20,
            marginTop: "0rem",
            // color: "#2F3D54",
            fontStyle: "italic",
          }}
        >
          Justin Geldenhuys
        </span>
      }
      description={
        <span
          style={{
            marginTop: "0rem",
            color: "black",
          }}
        >
          Director
        </span>
      }
    />
  </Card>
);
const ProductsHeader = () => (
  <div>
    <Fade delay={500}>
      <h1
        className="Mulish"
        style={{
          fontFamily: "Montserrat",
          margin: "auto",
          marginBottom: "1em",
          fontWeight: 600,
          textAlign: "center",
          lineHeight: "1.2em",
          fontSize: window.isPhone ? "25px" : "35px",
        }}
      >
        Why Choose<span style={{ color: "#F79489" }}> Perpetual Income</span>
      </h1>
      <p
        className="Mulish-center"
        style={{
          fontWeight: 300,
          fontSize: "16px",
          marginTop: "-1em",
          marginBottom: "1em",
          lineHeight: 1.3,

          textAlign: window.isPhone ? "center" : "",
          fontFamily: "Poppins",
          textAlign: "center",
        }}
      >
        I have had life-changing success using this system to view my story{" "}
        <Link style={{ color: "rgb(247, 148, 137)" }} to="/duepoint-intro">
          click here
        </Link>
        .
      </p>
    </Fade>
  </div>
);
const ProductsHeader2 = () => (
  <div>
    <h1
      className="Mulish"
      style={{
        margin: "auto",
        fontWeight: 500,
        fontSize: window.isPhone ? "25px" : "42px",
      }}
    >
      Meet The Team
    </h1>
    <div
      style={{
        paddingTop: "0.5em",
        margin: "auto",
        width: "2em",
        borderBottom: "2px solid black",
      }}
    ></div>
    {/* <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        Our product offerings go way beyond amazing product manufacturing. We
        pride ourselves on supplying as well as maintaining professional
        customer service.
      </p> */}
  </div>
);
const ProductsContent = () => (
  <Fade delay={500}>
    <Row style={{ margin: window.isPhone ? "1em" : "3em", marginTop: "2em" }}>
      <Col
        sm={24}
        md={6}
        className="card-hover"
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          border: "5px solid white",
          transition: "0.2s",
          borderRadius: "1em",
          margin: "auto",
        }}
      >
        <Card
          style={{
            backgroundColor: "transparent",
            marginTop: "0.5em",
            marginBottom: "0.5em",
            // padding: "1em",
            borderRadius: "0em",
            border: "none",

            textAlign: window.isPhone ? "center" : "left",
            fontSize: 40,
            color: "#2F3D54",
          }}
          // hoverable
          cover={
            <img
              style={{ width: "1.3em", margin: window.isPhone ? "auto" : "" }}
              src={ProIcon}
            ></img>
          }
        >
          <Meta
            title={
              <span>
                <p
                  className="Mulish"
                  style={{
                    fontSize: 18,
                    marginTop: "0.8em",
                    lineHeight: "1.3em",
                  }}
                >
                  DuePoint Wealth Creation System
                </p>
              </span>
            }
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 13,
                    marginTop: "-0.5rem",
                    marginBottom: "0em",
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                >
                  In-depth look at the company that has offered me a wealth
                  creation system.
                </p>{" "}
              </span>
            }
          />
          <a
            href="/#about-us"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black",
            }}
          >
            More Info
            <img
              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </a>
        </Card>
      </Col>

      <Col
        sm={24}
        md={6}
        className="card-hover"
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          border: "5px solid white",
          transition: "0.2s",
          borderRadius: "1em",
          margin: "auto",
        }}
      >
        <Card
          style={{
            backgroundColor: "transparent",
            marginTop: "0.5em",
            marginBottom: "0.5em",
            // padding: "1em",
            borderRadius: "0em",
            border: "none",
            textAlign: window.isPhone ? "center" : "left",
            fontSize: 40,
            color: "#2F3D54",
          }}
          // hoverable
          cover={
            <img
              style={{ width: "1.3em", margin: window.isPhone ? "auto" : "" }}
              src={ProIcon1}
            ></img>
          }
        >
          <Meta
            title={
              <span>
                <p
                  className="Mulish"
                  style={{
                    fontSize: 18,
                    marginTop: "0.8em",
                  }}
                >
                  My Road to Financial Security
                </p>
              </span>
            }
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 13,
                    marginTop: "-0.5rem",
                    marginBottom: "0em",
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                >
                  This is my own personal testimony of using this system and how
                  I made it a success.
                </p>{" "}
              </span>
            }
          />
          <Link
            to="./my-story"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black",
            }}
          >
            More Info
            <img
              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </Link>
          {/* <Link
            to="/"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black"
            }}
          >
            Sign Up
            <img

              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </Link> */}
        </Card>
      </Col>
      <Col
        sm={24}
        md={6}
        className="card-hover"
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          border: "5px solid white",
          transition: "0.2s",
          borderRadius: "1em",
          margin: "auto",
        }}
      >
        <Card
          style={{
            backgroundColor: "transparent",
            marginTop: "0.5em",
            marginBottom: "0.5em",
            // padding: "1em",
            borderRadius: "0em",
            border: "none",
            textAlign: window.isPhone ? "center" : "left",
            fontSize: 40,
            color: "#2F3D54",
          }}
          // hoverable
          cover={
            <img
              style={{ width: "1.3em", margin: window.isPhone ? "auto" : "" }}
              src={ProIcon2}
            ></img>
          }
        >
          <Meta
            title={
              <span>
                <p
                  className="Mulish"
                  style={{
                    fontSize: 18,
                    marginTop: "0.8em",
                  }}
                >
                  My Formula
                </p>
              </span>
            }
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 13,
                    marginTop: "-0.5rem",
                    marginBottom: "0em",
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                >
                  A step by step routine I follow to ensure my business is
                  always growing.
                </p>{" "}
              </span>
            }
          />
          <a
            href="/#about-us"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black",
            }}
          >
            More Info
            <img
              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </a>
        </Card>
      </Col>
      <Col
        sm={24}
        md={6}
        className="card-hover"
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          border: "5px solid white",
          transition: "0.2s",
          borderRadius: "1em",
          margin: "auto",
        }}
      >
        <Card
          style={{
            backgroundColor: "transparent",
            marginTop: "0.5em",
            marginBottom: "0.5em",
            // padding: "1em",
            borderRadius: "0em",
            border: "none",
            textAlign: window.isPhone ? "center" : "left",
            fontSize: 40,
            color: "#2F3D54",
          }}
          // hoverable
          cover={
            <img
              style={{ width: "1.3em", margin: window.isPhone ? "auto" : "" }}
              src={ProIcon3}
            ></img>
          }
        >
          <Meta
            title={
              <span>
                <p
                  className="Mulish"
                  style={{
                    fontSize: 18,
                    marginTop: "0.8em",
                  }}
                >
                  Testimonials
                </p>
              </span>
            }
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 13,
                    marginTop: "-0.5rem",
                    marginBottom: "0em",
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                >
                  Find out how other wealth engineers have found success using
                  this system.
                </p>{" "}
              </span>
            }
          />
          <a
            href="/#testimonials"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black",
            }}
          >
            More Info
            <img
              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </a>
          {/* <Link
            to="/"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black"
            }}
          >
            Get Started
            <img

              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </Link> */}
        </Card>
      </Col>
      {/* <Col sm={24} md={6} className="card-hover" style={{ paddingLeft: "1em", paddingRight: "1em", border: "5px solid white", transition: "0.2s", borderRadius: "1em", margin: "auto" }}>
        <Card
          style={{
            backgroundColor: "transparent",
            marginTop: "0.5em",
            marginBottom: "0.5em",
            // padding: "1em",
            borderRadius: "0em",
            border: "none",
            textAlign: window.isPhone ? "center" : "left",
            fontSize: 40,
            color: "#2F3D54"
          }}
          // hoverable
          cover={
            <img

              style={{ width: "1.3em", margin: window.isPhone ? "auto" : "", }}
              src={ProIcon3}
            ></img>
          }
        >
          <Meta
            title={
              <span>
                <p
                  className="Mulish"
                  style={{
                    fontSize: 18,
                    marginTop: "0.8em",

                  }}
                >
                  Gallery
                </p>
              </span>}
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 13,
                    marginTop: "-0.5rem",
                    marginBottom: "0em",
                    color: "black",
                    fontFamily: "Poppins",

                  }}
                >
                  Look at some of the many company vacations and lifestyle I have been blessed to now live.
                </p>{" "}
              </span>
            }
          />
          <Link
            to="./gallery"
            className="Mulish button-hover"
            style={{
              fontWeight: 200,
              fontSize: "14px",
              border: "none",
              color: "black"
            }}
          >
            View Gallery
            <img

              style={{ marginLeft: "0.5em", width: "1em" }}
              src={ArrowBottomIcon}
            ></img>
          </Link>

        </Card>
      </Col> */}
    </Row>
  </Fade>
);
const CertHeader = () => (
  <div id="tidbits">
    <Fade delay={500}>
      <h1
        className="Mulish"
        style={{
          fontFamily: "Montserrat",
          margin: "auto",
          fontWeight: 600,
          fontSize: window.isPhone ? "25px" : "35px",
          textTransform: "uppercase",
        }}
      >
        Linnette and Frikkie{" "}
        <span style={{ color: "#F79489" }}>Badenhorst</span>
      </h1>
      <p
        className="Mulish"
        style={{
          fontWeight: 300,
          fontSize: "15px",
          marginTop: "1em",
          marginBottom: "2em",
          lineHeight: 1.3,
          // paddingRight: window.isPhone ? "" : "3em",
          fontFamily: "Poppins",
        }}
      >
        Empowering every person and every business to achieve more, designed to
        get you better results.
      </p>{" "}
    </Fade>
  </div>
);
const CertHeader1 = () => (
  <div id="tidbits">
    <Fade delay={500}>
      <h1
        className="Mulish"
        style={{
          fontFamily: "Montserrat",
          margin: "auto",
          fontWeight: 600,
          fontSize: window.isPhone ? "25px" : "35px",
          textTransform: "uppercase",
        }}
      >
        As Seen <span style={{ color: "#F79489" }}>on TV</span>
      </h1>
      <p
        className="Mulish"
        style={{
          fontWeight: 300,
          fontSize: "15px",
          marginTop: "1em",
          marginBottom: "2em",
          lineHeight: 1.3,
          // paddingRight: window.isPhone ? "" : "3em",
          fontFamily: "Poppins",
        }}
      >
        All the insurance you will need to know that this is more than a
        lifeline but a stable future for you and your immediate family.
      </p>{" "}
    </Fade>
  </div>
);
const CertParagraph = () => (
  <div>
    <p
      className="Mulish"
      style={{
        fontSize: 18,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Our company endeavors to keep up to date with the increasing number of
      regulations and need for operational transparency in the industry. We
      pride ourselves in sending out quality controlled work welded only by
      fully certified staff.
    </p>{" "}
  </div>
);
const GalleryHeader = () => (
  <div>
    <img
      className="gallery-banner-img"
      style={{ margin: "auto", textAlign: "center" }}
      src={GalleryHome}
    ></img>{" "}
  </div>
);
const GalleryParagraph = () => (
  <div>
    <img
      className="gallery-banner-img"
      style={{ margin: "auto", textAlign: "center" }}
      src={GalleryHome}
    ></img>{" "}
  </div>
);
const ContactHeader = () => (
  <div id="contact-us">
    <Fade delay={500}>
      <h1
        className="Mulish"
        style={{
          fontFamily: "Montserrat",
          margin: "auto",
          fontWeight: 700,
          fontSize: window.isPhone ? "25px" : "35px",
          textTransform: "uppercase",
        }}
      >
        Get in Touch
      </h1>
      <p
        className="Mulish"
        style={{
          fontWeight: 300,
          fontSize: "14px",
          marginTop: "0.5em",
          marginBottom: "2em",
          lineHeight: 1.3,
          color: "#737373",
        }}
      >
        If you want to live this lifestyle, join the revolution and reach your
        full potential.
      </p>{" "}
    </Fade>
  </div>
);
//const ContactForm = () => (
//  <Form
//    onFinish={this.handleSubmit}
//    initialValues={{
//      remember: true,
//    }}
//    name="wrap"
//    labelCol={{ flex: "110px" }}
//    labelAlign="left"
//    labelWrap
//    wrapperCol={{ flex: 1 }}
//    colon={false}
//  >
//    <Row style={{}}>
//      <Col sm={24} md={11}>
//        <Form.Item name="name" rules={[{ required: true }]}>
//          <StyledInput placeholder="Company Name" />
//        </Form.Item>
//      </Col>
//      <Col sm={0} md={2}></Col>
//      <Col sm={24} md={11}>
//        <Form.Item name="email" rules={[{ required: true }]}>
//          <StyledInput placeholder="Company Email" />
//        </Form.Item>
//      </Col>
//      <Col sm={24} md={24}>
//        <Form.Item name="message" rules={[{ required: true }]}>
//          <StyledInput placeholder="Message" />
//        </Form.Item>
//      </Col>
//    </Row>
//    <Form.Item>
//      <Button
//        style={{
//          borderRadius: 50,
//          fontWeight: 500,
//          height: "3em",
//          width: "9em",
//          fontSize: "17px",
//          backgroundColor: "#D63629",
//          border: "black",
//          marginTop: "1em",
//        }}
//        type="primary"
//        htmlType="submit"
//      >
//        Send
//      </Button>
//    </Form.Item>
//  </Form>
//);
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successModal: false,
      visiblePopup: false,
      visiblePopupTV: false,
    };
    this.changeValue = this.changeValue.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }
  handleTabClick = key => {
    this.props.history.push(`/${key}`); // < == router router v4
  };
  componentDidMount() {
    document.title = "Perpetual Income";
    document.documentElement.scrollTop = 0;
  }
  onChange = e => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = value => {
    this.setState({
      inputValue: value,
    });
  };
  showModal = () => {
    this.setState({
      visiblePopup: true,
    });
  };
  showModalTV = () => {
    this.setState({
      visiblePopupTV: true,
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
      visiblePopupTV: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
      visiblePopupTV: false,
    });
  };
  handleClickDemo(form) {
    this.setState({
      FormChangeDemo: form,
    });
  }
  handleClickCall(form) {
    this.setState({
      FormChangeCall: form,
    });
  }
  showModalDrone = () => {
    this.setState({
      visiblePopupDrone: true,
    });
  };
  handleOk1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };

  handleCancel1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };
  handleSubmit = values => {
    this.onFinish(values);
  };
  changeValue = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };

  onFinish = values => {
    const MSG = {
      to: "linnette.badenhorst@outlook.com",
      from: "info@perpetualincome.co.za",
      //to: ["ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New contact request",
      text: " ",
      html: `

      <style type="text/css">
        body, #bodyTable{background - color:white}
        #emailHeader{background - color:white}
        #emailBody{background - color:#FFFFFF; border:1px solid #CCCCCC;}
        #emailFooter{background - color:#E1E1E1;}
      </style>
      <body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
        <center style="background-color:#E1E1E1;">
          <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
            <tr>
              <td align="center" valign="top" id="bodyCell">
                <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
                  <!-- HEADER ROW // -->
                  <tr>
                    <td align="center" valign="top">
                      <!-- CENTERING TABLE // -->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td align="center" valign="top">
                            <!-- FLEXIBLE CONTAINER // -->
                            <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                              <tr>
                                <td valign="top" width="500" class="flexibleContainerCell">

                                  <!-- CONTENT TABLE // -->
                                  <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                                    <tr>

                                      <td align="right" valign="middle" class="flexibleContainerBox">
                                        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                          <tr>
                                            <td align="left" class="textContent">
                                              <!-- CONTENT // -->

                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <!-- // FLEXIBLE CONTAINER -->
                          </td>
                        </tr>
                      </table>
                      <!-- // CENTERING TABLE -->
                    </td>
                  </tr>
                  <!-- // END -->
                </table>
                <!-- EMAIL BODY // -->
                <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
                  <!-- MODULE ROW // -->
                  <tr>
                    <td align="center" valign="top">
                      <!-- CENTERING TABLE // -->
                      <table border="0" cellpadding="0" cellspacing="0" style="color:#FFFFFF;" bgcolor="white">
                        <tr>
                          <td align="center" valign="top">
                            <!-- FLEXIBLE CONTAINER // -->

                            <table border="0" cellpadding="0" cellspacing="0" width="500" class="flexibleContainer">
                              <tr>
                                <td align="center" valign="top" width="500" class="flexibleContainerCell">

                                  <!-- CONTENT TABLE // -->

                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                                    <td align="center" valign="top" width="500" class="flexibleContainerCell  ">
                                      <img alt="" src="https://i.ibb.co/2dRzKr7/Group-10131.png" width="300" height="100" style="display: block; width:348px; max-width: 355px;; min-width: 189px;;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                                        <td align="center" valign="top" class="textContent">
                                          <h1 style="color:black;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                                          <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color:black;line-height:135%;">You have received a new contact request from <br />Perpetual Income website</h2>
                                        </td>
                                      </tr>
                                      </table>
                                      <!-- // CONTENT TABLE -->
                                    </td>
                                  </tr>
                                </table>
                                <!-- // FLEXIBLE CONTAINER -->
                              </td>
                            </tr>
                          </table>
                          <!-- // CENTERING TABLE -->
                        </td>
                      </tr>
                      <!-- MODULE ROW // -->
                      <tr mc:hideable>
                        <td align="center" valign="top">
                          <!-- CENTERING TABLE // -->
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
                            <tr>
                              <td align="center" valign="top">
                                <!-- FLEXIBLE CONTAINER // -->
                                <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                                  <tr>
                                    <td valign="top" width="500" class="flexibleContainerCell">
                                      <!-- CONTENT TABLE // -->
                                      <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                                        <tr>
                                          <td align="center" valign="top" class="flexibleContainerBox">
                                            <table border="0" cellspacing="0" cellpadding="0" >

                                              <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                                                <td class="role"><br /><br /><br /><br /><br />
                                                  <strong> Full Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                                                  <strong> Number </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.number}<br />
                                                  <strong> Email </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.email}
                                               
                                            
                                                  <br /><br /><br />
                                                </td>
                                              </tr>
                                              <table>
                                              </td>
                                            </tr>
                                          </table>
                                          <!-- // CONTENT TABLE -->

                                        </td>
                                      </tr>
                                    </table>
                                    <!-- // FLEXIBLE CONTAINER -->
                                  </td>
                                </tr>
                              </table>
                              <!-- // CENTERING TABLE -->
                            </td>
                          </tr>
                          <!-- // MODULE ROW -->


                          <!-- MODULE ROW // -->

                          <table border="0" cellpadding="0" cellspacing="0" width="100%" style="color:#FFFFFF;" bgcolor="white" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
                            <tr>
                              <td align="center" valign="top">

                                <table border="0" cellpadding="0" cellspacing="0" width="500" class="flexibleContainer">
                                  <tr>
                                    <td align="center" valign="top" width="500" class="flexibleContainerCell">


                                      <table border="0" cellpadding="30" cellspacing="0" width="100%">


                                        <tr>
                                          <td align="center" valign="top" class="textContent">

                                            <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2023@Perpetual Income</h2>

                                          </td>
                                        </tr>
                                      </table>
                                      <!-- // CONTENT TABLE -->

                                    </td>
                                  </tr>
                                </table>
                                <!-- // FLEXIBLE CONTAINER -->
                              </td>
                            </tr>
                          </table>
                          `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async res => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        name: "",
        email: "",
        number: "",
        message: "",
      });
    });
  };

  render() {
    return (
      <div>
        {/* <img
          className="eifeil-img"
          src={Eifel}
          style={{
            position: "absolute",
            top: 0,
            left: 100,
            opacity: 0.8,
            zIndex: -1,
            width: "45vw",
          }}
        ></img> */}
        {/* <img
          className="eifeil-img-mobile"
          src={EifelMobile}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0.8,
            zIndex: -1,
            width: "100vw",
          }}
        ></img> */}
        <Row
          style={{
            maxWidth: "90em",
            margin: "auto",
            padding: window.isPhone ? "1em" : "",
            paddingTop: "6em",
          }}
        >
          <Col sm={24} md={24} className="home-col">
            <Title />
          </Col>
        </Row>
        <Row
          style={{ margin: "auto", height: window.isPhone ? "10em" : "22vw" }}
        >
          <Col
            sm={24}
            md={24}
            style={{ position: "absolute", top: 0, zIndex: -1 }}
          >
            <img
              className="home-img"
              style={{
                margin: "auto",
                textAlign: "center",
                width: "100vw",
                maxHeight: "75em",
                minHeight: "50em",
              }}
              src={HomeImage}
            ></img>{" "}
          </Col>
        </Row>
        <Row
          id="why-us"
          style={{
            maxWidth: "110em",
            margin: "auto",
            textAlign: "left",
            marginTop: "0em",
          }}
        >
          <Col
            sm={24}
            md={24}
            style={{ paddingLeft: window.isPhone ? "" : "3em" }}
          >
            <ProductsHeader />
          </Col>
          <Col sm={24} md={24} style={{}}>
            <ProductsContent />
          </Col>
        </Row>{" "}
        <Row
          className="about-row-cert"
          style={{
            maxWidth: "90em",
            margin: "auto",
            textAlign: "center",
            marginTop: "0em",
            marginBottom: "2em",
          }}
        >
          <Col sm={24} md={24} style={{ width: "100vw" }}>
            <Button
              className="button-hover-1"
              onClick={this.showModalTV}
              style={{
                borderRadius: 50,
                fontWeight: 600,
                height: "3.5em",
                width: "13em",
                fontSize: "16px",
                paddingLeft: "1.5em",
                backgroundColor: "white",
                border: "1px solid #F79489",
                color: "#F79489",
              }}
              type="primary"
              htmlType="submit"
            >
              As Seen on TV
              <img
                style={{
                  marginLeft: "1.5em",
                  width: "2.5em",
                  transform: "rotate(-90deg)",
                }}
                src={ArrowBottomIcon}
              ></img>
            </Button>
          </Col>
          {/* <Col sm={24} md={12}>
            <CertParagraph />
          </Col> */}

          {/* <Slider {...settings}> */}
        </Row>
        {/* <div>
            <ReactPlayer width="98%"
              url="https://www.youtube.com/watch?v=rnnHbr9NRoQ&t=13s3"
            />
          </div>
          <div>
            <ReactPlayer width="98%"
              url="https://youtu.be/VgxnjwesIAg"
            />
          </div>
          <div>
            <ReactPlayer width="98%"
              url="https://youtu.be/OYcxatG9AJc"
            />
          </div> */}
        {/* </Slider> */}
        <div
          style={{
            backgroundColor: "#F79489",
            maxWidth: "70em",
            margin: window.isPhone ? "1em" : "auto",
            marginTop: window.isPhone ? "3em" : "6em",
            padding: window.isPhone ? "2em" : "4em",
            paddingBottom: window.isPhone ? "3em" : "5em",
            borderRadius: "3em",
            backgroundImage: `url(${VideoBack})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <Row style={{ maxWidth: "100em", margin: "auto" }}>
            <Col span={24} style={{ margin: "auto" }}>
              <Fade bottom>
                <h1
                  className="Mulish"
                  style={{
                    margin: "auto",
                    fontWeight: 800,
                    fontSize: "35px",
                    color: "white",
                  }}
                >
                  OUR <span>VISION</span>
                </h1>
                <p
                  className="Mulish"
                  style={{
                    fontWeight: 300,
                    fontSize: "16px",
                    marginTop: "1em",
                    marginBottom: "-0em",
                    lineHeight: 1.7,
                    color: "white",

                    textAlign: window.isPhone ? "center" : "",
                    fontFamily: "Poppins",
                  }}
                >
                  To stand as a shining example of the entrepreneurial spirit in
                  action, reflecting sound, ethical values, offering an
                  effective and affordable opportunity to turn your world
                  around, by building individual financial security and strong
                  personal growth.
                </p>{" "}
              </Fade>
            </Col>
          </Row>
          <Row style={{ maxWidth: "100em", margin: "auto", marginTop: "2em" }}>
            <Col
              span={24}
              style={{
                margin: "auto",
                textAlign: window.isPhone ? "" : "right",
              }}
            >
              <Fade bottom>
                <h1
                  className="Mulish"
                  style={{
                    margin: "auto",
                    fontWeight: 800,
                    fontSize: "35px",
                    color: "white",
                  }}
                >
                  OUR <span style={{ color: "white" }}>MISSION</span>
                </h1>
                <p
                  className="Mulish"
                  style={{
                    fontWeight: 300,
                    fontSize: "16px",
                    marginTop: "1em",
                    marginBottom: "-0em",
                    lineHeight: 1.7,
                    color: "white",

                    textAlign: window.isPhone ? "center" : "",
                    fontFamily: "Poppins",
                  }}
                >
                  To attract an ever-increasing number of like-minded
                  individuals who seek to make a positive difference to both
                  their own lives as well as those about them, who embrace our
                  culture of turning relationships into partnerships and People
                  Helping People and who gives meaning to our purpose of Turning
                  South Africa around! – Allan Schneeberger.
                </p>{" "}
              </Fade>
            </Col>
          </Row>
        </div>
        <div id="about-us" style={{ marginTop: "2em" }}>
          <Row
            className="about-row"
            style={{
              margin: "auto",
              marginTop: "3em",
              maxWidth: "100em",
              paddingLeft: window.isPhone ? "" : "3em",
            }}
          >
            <Col sm={24} md={1} style={{}}></Col>
            <Col sm={24} md={10}>
              <AboutHeader1 />
              <Link
                to="duepoint-intro"
                style={{
                  fontWeight: 500,
                  fontSize: "15px",
                  color: "#F79489",
                  marginTop: "2em",
                }}
              >
                Introduction & Background on Duepoint
                <img
                  style={{
                    marginLeft: "0.5em",
                    width: "1.2em",
                    transform: "rotate(-90deg)",
                  }}
                  src={ArrowBottomIcon}
                ></img>
              </Link>
              <br />
              <br />
              <Link
                to="/wealth-engineer"
                style={{
                  fontWeight: 500,
                  fontSize: "15px",
                  color: "#F79489",
                  marginTop: "2em",
                }}
              >
                What is a wealth engineer
                <img
                  style={{
                    marginLeft: "0.5em",
                    width: "1.2em",
                    transform: "rotate(-90deg)",
                  }}
                  src={ArrowBottomIcon}
                ></img>
              </Link>
              <br />
              <br />
              <Link
                to="/duepoint-products"
                style={{
                  fontWeight: 500,
                  fontSize: "15px",
                  color: "#F79489",
                  marginTop: "2em",
                }}
              >
                Duepoint Products
                <img
                  style={{
                    marginLeft: "0.5em",
                    width: "1.2em",
                    transform: "rotate(-90deg)",
                  }}
                  src={ArrowBottomIcon}
                ></img>
              </Link>
              <br />
              <br />
              <span
                onClick={this.showModal}
                style={{
                  fontWeight: 500,
                  fontSize: "15px",
                  color: "#F79489",
                  marginTop: "2em",
                }}
              >
                Sign up
                <img
                  style={{
                    marginLeft: "0.5em",
                    width: "1.2em",
                    transform: "rotate(-90deg)",
                  }}
                  src={ArrowBottomIcon}
                ></img>
              </span>
              <br />
              <br />
              {/* <Link to="duepoint-intro"
                style={{
                  fontWeight: 500,
                  fontSize: "15px",
                  color: "#F79489",
                  marginTop: "2em",
                }}
              >
                Eagles assistant
                <img
                  style={{ marginLeft: "0.5em", width: "1.2em", transform: "rotate(-90deg)" }}
                  src={ArrowBottomIcon}
                ></img>
              </Link>
              <br />
              <br /> */}
              <Link
                to="./certifications-licencing"
                style={{
                  fontWeight: 500,
                  fontSize: "15px",
                  color: "#F79489",
                  marginTop: "2em",
                }}
              >
                Certifications and Licencing
                <img
                  style={{
                    marginLeft: "0.5em",
                    width: "1.2em",
                    transform: "rotate(-90deg)",
                  }}
                  src={ArrowBottomIcon}
                ></img>
              </Link>
              {/* <Button className="button-hover-1" onClick={this.showModal}
                style={{
                  borderRadius: 50,
                  fontWeight: 600,
                  height: "3.5em",
                  width: "15.5em",
                  fontSize: "16px",
                  paddingLeft: "1.5em",
                  backgroundColor: "white",
                  border: "1px solid #F79489",
                  color: "#F79489",
                  marginTop: "0em",

                }}
                type="primary"
                htmlType="submit"
              >
                Join Duepoint Today
                <img
                  style={{ marginLeft: "1.5em", width: "2.5em", transform: "rotate(-90deg)" }}
                  src={ArrowBottomIcon}
                ></img>
              </Button> */}
            </Col>

            <Col sm={0} md={1} style={{}}></Col>
            <Col sm={24} md={12} style={{}}>
              <AboutParagraph1 />
            </Col>

            <Modal
              title="Basic Modal"
              visible={this.state.visiblePopupTV}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              style={{ textAlign: "center" }}
              width="90vw"
            >
              <Row>
                <Col sm={24} md={24}>
                  <ReactPlayer
                    width="98%"
                    height="40em"
                    url="https://youtu.be/Hz3yDx0NP1Y"
                  />
                </Col>
              </Row>
            </Modal>
            <Modal
              title="Basic Modal"
              visible={this.state.visiblePopup}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              style={{ textAlign: "center" }}
            >
              <img
                src={DuePoint}
                alt="Logo"
                style={{ width: "100%", marginTop: "3em" }}
              />
              <h3
                style={{
                  marginTop: "2em",
                  color: "black",
                  textAlign: "center",
                }}
              >
                Create monthly income with DuePoint and{" "}
                <span style={{ color: "#F79489" }}>Perpetual Income.</span>
                <br />
                <br />
                Your Lead Wealth Engineers are Linnette & Frikkie Badenhorst and
                their Wealth Engineer number to use to be part of the fastest
                growing DuePoint Channel is:
                <br />
                <br />
                <div style={{ border: "dotted 1px #F79489", padding: "1em" }}>
                  Wealth Engineer number :{" "}
                  <span style={{ color: "#F79489" }}>W1000000944</span>
                </div>
                <br />
                <span style={{ color: "black" }}>
                  Click here to start your online application
                </span>{" "}
                <br />
              </h3>{" "}
              <br />
              <a href="https://www.duepoint.net/" target="_blank">
                <Button
                  className="button-hover-1"
                  onClick={this.showModal}
                  style={{
                    borderRadius: 50,
                    fontWeight: 600,
                    height: "3.5em",
                    width: "15.5em",
                    fontSize: "16px",
                    paddingLeft: "1.5em",
                    backgroundColor: "white",
                    border: "1px solid #F79489",
                    color: "#F79489",
                    marginTop: "0em",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Join Duepoint Today
                  <img
                    style={{
                      marginLeft: "1.5em",
                      width: "2.5em",
                      transform: "rotate(-90deg)",
                    }}
                    src={ArrowBottomIcon}
                  ></img>
                </Button>
              </a>
            </Modal>
          </Row>
        </div>
        {/* <Row
          className="about-row-cert"
          style={{
            maxWidth: "70em",
            margin: "auto",
            textAlign: "center",
            marginTop: "2em",
          }}
        >
          <Col sm={24} md={24} style={{ width: "100vw" }} >
            <CertHeader />
          </Col>
        </Row>



        <Slider {...settings}>

          <div>
            <ReactPlayer width="98%"
              url="https://youtu.be/Tr-KZrFXUG0"
            />
          </div>
          <div>
            <ReactPlayer width="98%"
              url="https://youtu.be/Xaj1kJlUqZM"
            />
          </div>
          <div>
            <ReactPlayer width="98%"
              url="https://youtu.be/oU72AWr1ebg"
            />
          </div>
          <div>
            <ReactPlayer width="98%"
              url="https://youtu.be/wXmgW8evwW8"
            />
          </div>
          <div>
            <ReactPlayer width="98%"
              url="https://youtu.be/W90-cGsA7rI"
            />
          </div>

        </Slider> */}
        {/* <div>
          <Row
            className="about-row"
            style={{ margin: "auto", marginTop: "3em", maxWidth: "110em", paddingLeft: window.isPhone ? "" : "3em", }}
          >
            <Col sm={24} md={10}>
              <AboutParagraph2 />
            </Col>
            <Col sm={0} md={1}></Col>
            <Col sm={24} md={12} style={{}}>
              <AboutHeader2 />
            </Col>
          </Row>

        </div> */}
        <div
          id="testimonials"
          style={{
            textAlign: "center",
            marginTop: "0em",
            backgroundColor: "#F8F8F8",
            padding: "2em",
            paddingBottom: "4em",
          }}
        >
          <Fade delay={500}>
            <h1
              className="Mulish"
              style={{
                fontFamily: "Montserrat",
                margin: "auto",
                fontWeight: 600,
                fontSize: window.isPhone ? "25px" : "35px",
                textTransform: "uppercase",
                color: "#F79489",
              }}
            >
              Testimonials
            </h1>
            <p
              className="Mulish"
              style={{
                fontWeight: 300,
                fontSize: "15px",
                marginTop: "1em",
                marginBottom: "2em",
                lineHeight: 1.3,
                // paddingRight: window.isPhone ? "" : "3em",
                fontFamily: "Poppins",
              }}
            >
              Find out how other wealth engineers have found success using this
              system.
            </p>{" "}
          </Fade>

          <Slider {...settingsTestimonials}>
            <div class="carousel-item active">
              <div
                style={{
                  textAlign: "center",
                  padding: window.isPhone ? "1em" : "3em",
                  paddingLeft: window.isPhone ? "" : "5em",
                  paddingRight: window.isPhone ? "" : "5em",
                }}
              >
                <h2>I am proud of you</h2>

                <p>
                  Hello Linnette,
                  <br /> <br />
                  Do you know that you are unique? <br />
                  As a network marketer, the work you do inspires me. And I am
                  proud to be associated with you. <br />
                  You too should be - of the tremendous work you do and lives
                  you impact!
                </p>

                <h4>Eric Worre</h4>
                <Rate disabled defaultValue={5} />
              </div>
            </div>
            <div class="carousel-item active">
              <div
                style={{
                  textAlign: "center",
                  padding: window.isPhone ? "1em" : "3em",
                  paddingLeft: window.isPhone ? "" : "5em",
                  paddingRight: window.isPhone ? "" : "5em",
                }}
              >
                <h2> Linnette, in my opinion the leading leader in Duepoint</h2>
                {/* <img src={DuePoint} style={{ marginTop: "2em" }} /> */}
                <p>
                  In 2021, I decide to make a change and joined DuePoint. First,
                  I thoroughly investigated DuePoint’s business practice and was
                  satisfied that it is an excellent, legal business that offers
                  people who are willing to work hard, the opportunity to build
                  his/her own business and the ability to create a good
                  sustainable income without a huge capital layout. What
                  impressed me most was the DuePoint tools that allow new Wealth
                  Engineers to independently build their own businesses.
                </p>

                <h4>ERINA LODEWYCKX</h4>
                <Rate disabled defaultValue={5} />
              </div>
            </div>
            <div class="carousel-item active">
              <div
                style={{
                  textAlign: "center",
                  padding: window.isPhone ? "1em" : "3em",
                  paddingLeft: window.isPhone ? "" : "5em",
                  paddingRight: window.isPhone ? "" : "5em",
                }}
              >
                {/* <h2> Linnette, in my opinion the leading leader in Duepoint</h2> */}
                {/* <img src={DuePoint} style={{ marginTop: "2em" }} /> */}
                <p>
                  Thank you for going the extra mile to teach us how to use
                  social media correctly, Linnette.
                </p>

                <h4 style={{ textTransform: "uppercase" }}>
                  Carin Van Rooyen Pienaar
                </h4>
                <Rate disabled defaultValue={5} />
              </div>
            </div>
            <div class="carousel-item active">
              <div
                style={{
                  textAlign: "center",
                  padding: window.isPhone ? "1em" : "3em",
                  paddingLeft: window.isPhone ? "" : "5em",
                  paddingRight: window.isPhone ? "" : "5em",
                }}
              >
                <h2> Thank you Linnette</h2>
                {/* <img src={DuePoint} style={{ marginTop: "2em" }} /> */}
                <p>You are truly an inspiration. ❤️</p>

                <h4 style={{ textTransform: "uppercase" }}>Dawn Grant</h4>
                <Rate disabled defaultValue={5} />
              </div>
            </div>
          </Slider>
        </div>
        <Row
          style={{
            maxWidth: "30em",
            margin: "auto",
            textAlign: "center",
            marginTop: window.isPhone ? "1em" : "6em",
            padding: window.isPhone ? "2em" : "",
            paddingBottom: 0,
          }}
        >
          <Col sm={24} md={24}>
            <ContactHeader />
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "60em",
            margin: "auto",
            textAlign: "center",
            marginTop: "1em",
          }}
        >
          <Col sm={24} md={24} style={{ padding: window.isPhone ? "1em" : "" }}>
            <Form
              onFinish={this.handleSubmit}
              initialValues={{
                remember: true,
              }}
              name="wrap"
              layout="vertical"
              colon={false}
            >
              <Row style={{}}>
                <Col xs={24} md={7}>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: "" }]}
                    hasFeedback
                  >
                    <StyledInput
                      placeholder="First & Last Name"
                      style={{ width: window.isPhone ? "100%" : "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={1}></Col>
                <Col xs={24} md={7}>
                  <Form.Item
                    name="number"
                    rules={[{ required: true, message: "" }]}
                    hasFeedback
                  >
                    <StyledInput
                      maxLength={10}
                      placeholder="Contact Number"
                      style={{ width: window.isPhone ? "100%" : "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={0} md={1}></Col>
                <Col xs={24} md={7}>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: "", type: "email" }]}
                    hasFeedback
                  >
                    <StyledInput
                      placeholder="Email"
                      style={{ width: window.isPhone ? "100%" : "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  style={{
                    borderRadius: 50,
                    fontWeight: 500,
                    height: "3.5em",
                    width: "10em",
                    fontSize: "15px",
                    marginLeft: window.isPhone ? "" : "-2em",
                    paddingLeft: "2em",
                    backgroundColor: "#F79489",
                    border: "black",
                    marginTop: "0em",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                  <img
                    style={{
                      marginLeft: "1.5em",
                      width: "2.5em",
                      transform: "rotate(-90deg)",
                    }}
                    src={ArrowBottomIcon}
                  ></img>
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <img
            className="contact-image"
            style={{
              marginTop: "-24em",
              left: 0,
              zIndex: 2,
              position: "absolute",
            }}
            src={WingLeft}
          ></img>
          <img
            className="hori-line"
            style={{
              marginTop: "-22em",
              right: 0,
              zIndex: 1,
              position: "absolute",
            }}
            src={WingRight}
          ></img>{" "}
        </Row>{" "}
        <SuccessModal
          open={this.state.successModal}
          onClose={this.handleCloseSuccessModal}
          center
        >
          <img src={LogoModal} alt="Logo" style={{ width: "65%" }} />
          <h3
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
            }}
          >
            Thank you for contacting
            <span style={{ color: "#F79489" }}>
              <br />
              Perpetual Income
            </span>{" "}
            <br />
            <span style={{ color: "black" }}>
              We will be in touch shortly.
            </span>{" "}
            <br />
          </h3>{" "}
          <br />
          <Button
            onClick={this.handleCloseSuccessModal}
            style={{
              margin: "auto",
              height: "2.9em",

              fontSize: "1rem",
              fontWeight: 500,
              borderRadius: "5em",
              backgroundColor: "#F79489",
              color: "white",
              border: "none",
              marginBottom: "1em",
              paddingLeft: "3em",
              paddingRight: "3em",
              marginRight: "0em",
              textShadow: "none",
            }}
            type="primary"
          >
            Close
          </Button>{" "}
        </SuccessModal>
      </div>
    );
  }
}

export default Home;
